<script>
import FilesListItem from '@/components/FilesListItem'
import FilePreview from '@/components/FilePreview'
import FullScreenFilePreview from '@/components/FullScreenFilePreview'
import { MediaStatus } from '@/lib/enums'
import IconFullScreen from '@/assets/imgs/icons/icon-full-screen.svg'

export default {
  name: 'FilesList',
  components: {
    FilesListItem,
    FilePreview,
    FullScreenFilePreview,
    IconFullScreen
  },
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      fileList: [],
      selectedFile: null,
      fullScreenFile:null,
    }
  },
  methods: {
    selectFile(f) {
      if (f?.status !== MediaStatus.Success) return
      this.selectedFile = f
    },
    setFullScreenFile(f) {
      if (f?.status !== MediaStatus.Success) return
      this.fullScreenFile = f
    },
    closeFullScreen(){
      this.fullScreenFile = null
    }
  },
  created(){
    this.fileList = this.files
    this.selectedFile = this.fileList[0];
  },
  watch:{
    files: function(){
      this.fileList = this.files
      this.selectedFile = this.fileList[0];
    }
  }
}
</script>

<template>
<div class="w-100 flex-grow file-list-section h-full overflow-hidden">
  <div class="h-full overflow-y-auto custom-scrollbar pr-4">
    <FilesListItem
      v-for="file in fileList"
      :key="file.id"
      :file="file"
      :isActive="selectedFile.id === file.id"
      @click="selectFile(file)"
    />
  </div>
  <div v-if="selectedFile" class="h-full overflow-hidden position-relative px-4 mx-auto file-preview-small">
    <div class="mx-auto h-full overflow-y-auto custom-scrollbar">
      <FilePreview :file="selectedFile"/>
    </div>
{{selectedFile}}
    <v-btn
      v-if="!selectedFile?.details.originalFormat?.startsWith('audio')"
      class="full-screen-mode-button text-black pa-2"
      flat
      elevation="2"
      @click="setFullScreenFile(selectedFile)"
      >
      <IconFullScreen/>
    </v-btn>
    <FullScreenFilePreview v-if="fullScreenFile" :file="fullScreenFile" @closeFullScreen="closeFullScreen"/>
  </div>
</div>
</template>

<style lang="scss" scoped>
@import 'src/assets/_variables.scss';
.file-list-section{
  display: grid;
  grid-template-columns: minmax(auto, 300px) 1fr;
}
.file-preview-small{
  max-width: 380px;
  width: 100%;
  &:hover{
    .full-screen-mode-button{
      opacity: 1;
    }    
  }
}
.full-screen-mode-button{
  position: absolute;
  top:5px;
  right:30px;
  z-index:10;
  min-width: auto;
  box-shadow: $admin-shadow;
  opacity: 0;
}
</style>
