<script>

export default {
  name: 'UiMusicMixer',
  emits: ['close'],
  methods: {
    revolume(code, ev) {
      const bounds = ev.target.getBoundingClientRect()
      const y = ev.offsetY
      const h = bounds.height
      let pos = h - y
      let ph = h
      // console.log(ev.target)
      if (ev.target.className.includes('volume-line')) {
        // click on volume
        const p = ev.target.parentNode
        const pbounds = p.getBoundingClientRect()
        ph = pbounds.height
        // console.log(`PARENT ${ph}; `, p, pbounds)
        // pos = ph - h - y
      }
      const val = Math.round(pos*100/ph)
      
      // console.log(`revolume: y: ${y}; h: ${h}; ph: ${ph}; pos: ${pos}; %: ${val}`)

      this.$store.commit('ui/setVolume', {code, val})
    },
    toggle(code) {
      const val = this.$store.state.ui.volume[code]
      const next = val > 10 ? 5 : 50
      this.$store.commit('ui/setVolume', {code, val: next})
    },
    clickOutside() {
      this.$emit('close')
    }
  },
  watch:{
    '$store.state.ui.hasMedia'(){
      console.log("It's happened!")
      console.log(this.$store.state.ui.volume);
      //this.toggle('music');
    }
  }
}
</script>

<template>
<div class="ui-panel pa-2 rounded d-flex" v-click-outside="clickOutside">
  <div class="volume-bar rounded d-flex flex-column" @click.stop.prevent="revolume('music', $event)">
    <div class="volume-area">
      <div class="volume-line rounded flex-grow-1" :style="{
        height: $store.state.ui.volume.music + '%',
        background: $store.state.ui.volume.music > 9 ? 'green' : 'red'
      }"/>
    </div>
    <v-icon
      class="line-icon"
      :class="{
        'text-white': $store.state.ui.volume.music > 9,
        'text-red': $store.state.ui.volume.music < 9,
      }"
      @click.stop="toggle('music')"
    >mdi-music-clef-treble</v-icon>
  </div>
  <!-- <div v-if="$store.state.ui.hasMedia" class="volume-bar rounded flex flex-column ml-2" @click.stop.prevent="revolume('player', $event)">
    <div class="volume-area">
      <div class="volume-line rounded flex-grow-1" :style="{
        height: $store.state.ui.volume.player + '%',
        background: $store.state.ui.volume.player > 9 ? 'green' : 'red'
      }"/>
    </div>
    <v-icon
      class="line-icon"
      :class="{
        'text-white': $store.state.ui.volume.player > 9,
        'text-red': $store.state.ui.volume.player < 9,
      }"
      @click.stop="toggle('player')"
    >mdi-folder-music-outline</v-icon>
  </div> -->
</div>
</template>

<style scoped>
.ui-panel {
  background: rgba(0, 0, 0, 0.7);
  height: 220px;
}
.volume-bar {
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  width: 20px;
  height: 100%;
  z-index:100;
}
.volume-area {
  position: absolute;
  bottom: 30px;
  width: 20px;
  height: calc(100% - 30px);
  width: 100%;
  background: rgba(0, 0, 0, 0.6);

}
.volume-line {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
}
.line-icon {
  position: absolute;
  bottom: -4px;
  left: -2px;
}
</style>
