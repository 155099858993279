<script>
import MediaControlPrev from "@/assets/imgs/icons/media-control-prev.svg";
import MediaControlNext from "@/assets/imgs/icons/media-control-next.svg";
import MediaControlPlay from "@/assets/imgs/icons/media-control-play.svg";
import MediaControlPause from "@/assets/imgs/icons/media-control-pause.svg";
import waitFor from "@/utils/waitFor";

export default {
  name: "MediaPlayer",
  components: {
    MediaControlPrev,
    MediaControlNext,
    MediaControlPlay,
    MediaControlPause,
  },
  props: {
    file: {
      type: Object,
      default: null,
      required: true,
    },
    type: {
      type: Number,
      default: null,
      required: true,
    },
    mode: {
      type: String,
      default: "full",
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    displayName: {
      type: String,
      default: "",
    },
  },
  emits: ["prevFile", "nextFile"],
  data() {
    return {
      media: null,
      barWidth: null,
      duration: null,
      currentTime: null,
      //currentTrack: null,
      //currentTrackIndex: 0,
      transitionName: null,
      isPlaying: false,
    };
  },
  async created() {
    await waitFor(0);
    this.media = this.$refs.media;

    this.$store.commit("ui/setHasMedia", true);
    //console.log('file', this.file)
    this.updateVolume();
    if (this.media) {
      this.media.ontimeupdate = () => {
        this.generateTime();
      };
      this.media.onloadedmetadata = () => {
        this.generateTime();
      };
      this.media.onended = () => {
        this.mediaEnd();
      };
      if (this.autoplay) {
        this.media.load();
        this.media.play();
      }
    }

    this.$store.commit("ui/pausePlayer");
  },
  beforeUnmount() {
    this.$store.commit("ui/setHasMedia", false);
    this.$store.commit("ui/unpausePlayer");
  },
  methods: {
    updateVolume() {
      const p = this.media;
      if (!p) return;
      const next = this.$store.state.ui.volume.player;
      const vol = next / 100;
      // console.log('next player vol', vol)
      if (vol < 0) return;
      p.volume = vol;
      if (vol < 0.08) p.volume = 0;
    },
    togglePlay() {
      if (this.media.paused) {
        this.media.play();
        this.isPlaying = true;
      } else {
        this.media.pause();
        this.isPlaying = false;
      }
    },
    generateTime() {
      let width = (100 / this.media.duration) * this.media.currentTime;
      this.barWidth = width + "%";
      let durmin = Math.floor(this.media.duration / 60);
      let dursec = Math.floor(this.media.duration - durmin * 60);
      let curmin = Math.floor(this.media.currentTime / 60);
      let cursec = Math.floor(this.media.currentTime - curmin * 60);
      if (durmin < 10) {
        durmin = "0" + durmin;
      }
      if (dursec < 10) {
        dursec = "0" + dursec;
      }
      if (curmin < 10) {
        curmin = "0" + curmin;
      }
      if (cursec < 10) {
        cursec = "0" + cursec;
      }
      this.duration = durmin + ":" + dursec;
      this.currentTime = curmin + ":" + cursec;
    },
    updateBar(x) {
      let progress = this.$refs.progress;
      let maxduration = this.media.duration;
      let position = x - progress.getBoundingClientRect().left;
      let percentage = (100 * position) / progress.offsetWidth;
      if (percentage > 100) {
        percentage = 100;
      }
      if (percentage < 0) {
        percentage = 0;
      }
      this.barWidth = percentage + "%";
      this.media.currentTime = (maxduration * percentage) / 100;
      if (this.isPlaying) {
        this.media.play();
      }
    },
    clickProgress(e) {
      this.isTimerPlaying = true;
      this.media.pause();
      this.updateBar(e.pageX);
    },
    mediaEnd() {
      (this.isPlaying = false), this.resetPlayer();
    },
    resetPlayer() {
      this.barWidth = 0;
      this.media.currentTime = 0;
      //this.media.src = this.currentTrack.source;
      setTimeout(() => {
        if (this.isPlaying) {
          this.media.play();
        } else {
          this.media.pause();
        }
      }, 300);
    },
    revolume(code, ev) {
      const bounds = ev.target.getBoundingClientRect();
      const y = ev.offsetY;
      const h = bounds.height;
      let pos = h - y;
      let ph = h;
      // console.log(ev.target)
      if (ev.target.className.includes("volume-line")) {
        // click on volume
        const p = ev.target.parentNode;
        const pbounds = p.getBoundingClientRect();
        ph = pbounds.height;
        // console.log(`PARENT ${ph}; `, p, pbounds)
        // pos = ph - h - y
      }
      const val = Math.round((pos * 100) / ph);

      // console.log(`revolume: y: ${y}; h: ${h}; ph: ${ph}; pos: ${pos}; %: ${val}`)

      this.$store.commit("ui/setVolume", { code, val });
    },
    toggle(code) {
      const val = this.$store.state.ui.volume[code];
      const next = val > 10 ? 5 : 50;
      this.$store.commit("ui/setVolume", { code, val: next });
    },
  },
  watch: {
    async file() {
      await waitFor(0);
      this.media = this.$refs.media;
      this.media.ontimeupdate = () => {
        this.generateTime();
      };
      this.media.onloadedmetadata = () => {
        this.generateTime();
      };
      this.media.onended = function () {
        this.mediaEnd();
      };
    },
    "$store.state.ui.volume.player"() {
      this.updateVolume();
    },
  },
};
</script>

<template>
  <div
    class="media-preview-container mx-auto"
    :class="`media-preview-container-${mode}`"
  >
    <div
      class="media-preview"
      :class="{ 'h-full': mode === 'fit' }"
      v-if="type === 4"
    >
      <!--
          <v-img
            cover
            :aspect-ratio="1/1"
            lazy-src="https://picsum.photos/id/11/10/6"
            src="https://picsum.photos/id/11/500/300"
          ></v-img>
            :src="file.details.url.split('?').shift()"
        -->
      <div class="d-none">
        <audio :src="file.details.url" ref="media" />
      </div>
    </div>

    <!-- Cloudflare Player -->
    <div class="media-preview" v-if="type === 5 && file.cloudflareStreamUID">
      <div v-if="mode === 'fit'" class="h-full">
        <iframe
          :src="`https://iframe.videodelivery.net/${file.cloudflareStreamUID}?autoplay=true`"
          class="preview-video h-full"
          style="
            border: none;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
          "
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"
        ></iframe>
      </div>
      <v-responsive v-else :aspect-ratio="16 / 9">
        <iframe
          :src="`https://iframe.videodelivery.net/${file.cloudflareStreamUID}?autoplay=true`"
          class="preview-video h-full"
          style="
            border: none;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
          "
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"
        ></iframe>
      </v-responsive>
    </div>
    <!-- HTML5 Video Embed -->
    <div class="media-preview" v-if="type === 5 && !file.cloudflareStreamUID">
      <div v-if="mode === 'fit'" class="h-full">
        <video
          class="preview-video h-full"
          :src="file.details.url"
          ref="media"
        ></video>
      </div>
      <v-responsive v-else :aspect-ratio="16 / 9">
        <video
          class="preview-video"
          :src="file.details.url"
          ref="media"
        ></video>
      </v-responsive>
    </div>
    <!-- Media Controls -->
    <div
      v-if="!file.cloudflareStreamUID"
      class="media-controls pt-5 px-4"
      :class="`media-controls-${mode}`"
      ref="progress"
    >
      <div class="d-flex justify-content-between">
        <p class="media-meta">
          {{ displayName?.length > 0 ? displayName : file.details.filename }}
        </p>
        <div class="media-time">
          <span>{{ currentTime }}</span> / <span>{{ duration }}</span>
        </div>
      </div>
      <div class="media-track bg-white mt-2 rounded" @click="clickProgress">
        <div
          class="
            media-track-progress
            bg-primary
            h-full
            position-relative
            rounded
          "
          :style="{ width: barWidth }"
        >
          <button class="media-track-progress-handle"></button>
        </div>
      </div>
      <div
        class="
          media-control-buttons
          d-flex
          mt-4
          flex-wrap
          justify-content-center
          align-items-center
        "
      >
        <button class="d-none">
          <MediaControlPrev @click="$emit('prevFile')" />
        </button>
        <button class="play-button">
          <MediaControlPlay v-if="!isPlaying" @click="togglePlay" />
          <MediaControlPause v-else @click="togglePlay" />
        </button>
        <div class="volume-button-wrapper position-relative">
          <div
            class="volume-area"
            @click.stop.prevent="revolume('player', $event)"
          >
            <div
              class="volume-line rounded flex-grow-1"
              :style="{
                height: $store.state.ui.volume.player + '%',
                background: $store.state.ui.volume.player > 9 ? 'green' : 'red',
              }"
            />
          </div>
          <button class="volume-button" @click.stop="toggle('player')">
            <v-icon
              v-if="$store.state.ui.volume.player > 9"
              class="text-white"
              @click.stop="toggle('player')"
            >
              mdi-volume-high
            </v-icon>
            <v-icon v-if="$store.state.ui.volume.player < 9" class="text-white">
              mdi-volume-off
            </v-icon>
          </button>
        </div>
        <button class="d-none">
          <MediaControlNext @click="$emit('nextFile')" />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.media-preview-container-fit {
  position: relative;
  height: 100%;
  &:hover {
    .media-controls-fit {
      opacity: 1;
    }
  }
}
.media-controls-fit {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  opacity: 1;
  transition: 0.5s;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
}
.media-control-buttons {
  button {
    margin: 5px 10px;
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
    &:disabled {
      opacity: 0.25;
      pointer-events: none;
    }
    svg {
      display: block;
    }
  }
  .play-button {
    svg {
      width: 60px;
      height: 60px;
    }
  }
}
.media-track {
  height: 4px;
  cursor: pointer;
}
.media-meta {
  flex-shrink: 1;
  overflow: hidden;
}
.media-time {
  flex-shrink: 0;
  margin-left: 10px;
}
.media-track-progress-handle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(var(--v-theme-primary));
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  right: 0;
}
.preview-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Volume Control
.volume-button {
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.volume-area {
  position: absolute;
  bottom: calc(100% - 10px);
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 200px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  opacity: 0;
  transition: 0.5s;
  z-index: 2;
  pointer-events: none;
}
.volume-button-wrapper {
  z-index: 1;
  &:hover .volume-area {
    opacity: 1;
    pointer-events: auto;
  }
}
.volume-line {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
}
</style>
