<script>
import PlaycanvasPlayer from '@/components/PlaycanvasPlayer'
import playcanvasSettings from '@/playcanvas.settings'
import Overlay3D from '@/components/Overlay3D.vue'
import OverlayPage from '@/components/OverlayPage.vue'
import UiMusicPlayer from '@/components/UiMusicPlayer.vue'
import NotificationPopup from '@/components/notification/NotificationPopup'
import MediaPlayer from '@/components/MediaPlayer'

export default {
  name: 'Engine3D',
  components: {
    UiMusicPlayer,
    PlaycanvasPlayer,
    MediaPlayer,
    Overlay3D,
    OverlayPage,
    NotificationPopup
  },
  data(){
    return{
      hasImmidiateNotification: false,
    }
  },
  computed: {
    sceneName() {
      return this.$route.params.scene
    },
    settings() {
      return playcanvasSettings
    }
  },
  methods: {
    on3DTouchpoint(tp) {
      // console.log('3dtp', tp)
      this.$store.commit('scene/setLastTouchPoint', tp)
      // this.lastTP = tp
      const aid = this.$store.state.scene && this.$store.state.scene.touchpoints
        ? (this.$store.state.scene.touchpoints[tp.id] || null)
        : null
      if (!aid)
        return
      this.$router.push({
        name: 'ActivityOverlay',
        params: { id: aid }
      })
    },
    on3DEasterEgg(tp) {
      const aid = this.$store.state.scene && this.$store.state.scene.eastereggs
        ? (this.$store.state.scene.eastereggs[tp.id] || null)
        : null
      //console.log('3deg', tp, aid)
      if (!aid)
        return
      this.$router.push({
        name: 'ActivityOverlay',
        params: { id: aid }
      })
    },
    sceneEnter(scene) {
      this.$store.commit('scene/enter', scene)
      // console.log('scene enter', scene)
      // this.$store.commit('ui/setLeaderboard', 'global')
    },
    sceneLeave(scene) {
      this.$store.commit('scene/leave', scene)
    }
  }
}
</script>

<template>
<div class="full-screen scrollbar-theme-dark">
  <UiMusicPlayer
    :sceneName="sceneName"
  />
  <PlaycanvasPlayer
    v-if="settings"
    :settings="settings"
    :sceneName="sceneName"
    @touchpoint="on3DTouchpoint"
    @easteregg="on3DEasterEgg"
    @sceneEnter="sceneEnter"
    @sceneLeave="sceneLeave"
  />
  <Overlay3D v-if="!$store.state.pc.splash"/>
  <NotificationPopup v-if="hasImmidiateNotification" />
  <OverlayPage class="activity-overlay"
    v-if="$store.state.ui.videoNotification"
    @close="$store.commit('ui/setVideoNotification',null)"
  >
    <MediaPlayer
      mode="fit"
      :file="$store.state.ui.videoNotification"
      autoplay
      :type="5"
    />
  </OverlayPage>

</div>
</template>

<style scoped>
.full-screen {
  height: 100vh;
}
</style>
