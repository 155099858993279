<script>
import Papa from "papaparse";

export default {
  name: 'CsvPreview',
  props: {
    src: {
      type: String,
      default: null,
      required: true
    }
  },
  data(){
    return{
      csv: []
    }
  },
  mounted(){
    this.getCsvData(this.src);
  },
  methods: {
    getCsvData(url){
      fetch(url)
      .then(response => response.text())
      .then(data => this.csvTotable(data));
    },
    csvTotable(csv){
      var csvObject = Papa.parse(csv);
      this.csv = csvObject.data
    }
  }
}
</script>

<template>
  <div class="csv-table-wrappper">
    <table class="csv-table">
      <tr v-for="(row, index) in csv" :key="index">
        <td v-for="(col, index) in row" :key="index">{{ col }}</td>  
      </tr>  
    </table>
  </div>
</template>

<style lang="scss" scoped>
.csv-table-wrappper{
  overflow: auto;
  table{
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    td{
      border:1px solid #ddd;
      padding: 10px;
      white-space: nowrap;
    }
  }
}
</style>