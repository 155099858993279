<script>
import { useI18n } from 'vue-i18n'
import TextField from '@/components/fields/Text'
import AlertIcon from '@/assets/imgs/icons/alert.svg'
import waitFor from '@/utils/waitFor'
import * as Enums from '@/lib/enums'

import FlagUSA from '@/assets/imgs/icons/flags/usa.svg'
import FlagFrance from '@/assets/imgs/icons/flags/france.svg'
import FlagSpain from '@/assets/imgs/icons/flags/spain.svg'
import FlagUK from '@/assets/imgs/icons/flags/uk.svg'

export default {
  name: 'Profile',
  components: {
    TextField,
    AlertIcon,
  },
  data() {
    return {
      profile: {
        displayName: this.$store.state.user.data?.displayName || '',
        email: this.$store.state.user.data?.email || ''
      },
      passwords: {
        current: '',
        next: '',
        confirm: ''
      },
      error: '',
      loading: false,
      loadingAvatar: false
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  watch: {
    '$store.state.user.data.phone'(next) {
      this.profile.phone = next
    },
    '$store.state.user.data.displayName'(next) {
      this.profile.displayName = next
    }
  },
  methods: {
    async save() {
      this.$store.commit('ui/setBusy', true)
      this.loading = true
      const res = await this.$store.dispatch('user/updateProfile', {
        profile: this.profile,
        passwords: this.passwords
      })
      this.loading = false
      this.$store.commit('ui/setBusy', false)
      if (res.error) {
        this.error = res.error
        await waitFor(3000)
        this.error = ''
      }
    },
    async updateAvatar(ev) {
      const file = ev.target.files[0]
      if (!file)
        return
      console.log('load start')
      this.loadingAvatar = true
      await this.$store.dispatch('user/updateAvatar', file)
      console.log('load end')
      this.loadingAvatar = false
    },
    async deleteAvatar() {
      this.loadingAvatar = true
      await this.$store.dispatch('user/deleteAvatar')
      this.loadingAvatar = false
    }
  },
  computed: {
    formUpdated() {
      const profileUpdated = (this.profile.displayName !== this.$store.state.user.data?.displayName)
      const p = this.passwords
      const passwordsUpdated = p.current.length || p.next.length || p.confirm.length
      const passwordsValid = p.current.length && p.next.length && p.next === p.confirm
      return passwordsUpdated
        ? passwordsValid
        : profileUpdated
    },
    avatarUrl() {
      return this.$store.state.user.data?.avatar || ''
    },
    countriesOptions() {
      return ['US','FR','ES','GB'].reduce((acc,key) => {
        acc[Enums.Country[key]] = this.t(`countries.${key}`)
        return acc
      }, {})
    },
    countriesFlags() {
      return {
        [Enums.Country.US]: FlagUSA,
        [Enums.Country.FR]: FlagFrance,
        [Enums.Country.ES]: FlagSpain,
        [Enums.Country.GB]: FlagUK
      }
    },
  }
}
</script>

<template>
  <div class="h-full d-flex flex-column">

    <h2>{{t('userPage.profile.title')}}</h2>
    <hr class="opacity mb-4" />
    <div class="flex-grow overflow-y-auto custom-scrollbar">
      <!-- TODO : Refactor form UI -->
      <div v-if="error !== ''" class="error-message-2d rounded-5 pa-1 mb-1">
        <AlertIcon class="mx-2 alert-icon"/> {{t(error)}}
      </div>
      <div class="d-flex flex-wrap profile-detail-wrapper px-1">
        <div class="profile-details flex-grow-1">
          <div>
            <label class="d-block mb-1">{{ t('userPage.profile.fields.name.label') }}</label>
            <TextField
              :disabled="loading || $store.state.ui.busy"
              v-model="profile.displayName"
              autocomplete="off"
              class="mb-4"
              :placeholder="t('userPage.profile.fields.name.placeholder')"
            />
          </div>

          <div>
            <label class="d-block mb-1">{{ t('userPage.profile.fields.email.label') }} - <span class="font-size-12">{{ t('userPage.profile.fields.email.notice') }}</span></label>
            <div class="email-field mb-4">{{profile.email}}</div>
          </div>

          <h2>{{t('userPage.profile.passwordTitle')}}</h2>
          <hr class="opacity mb-4" />
          <div>
            <label class="d-block mb-1">{{ t('userPage.profile.fields.passwordCurrent.label') }}</label>
            <TextField
              :disabled="loading || $store.state.ui.busy"
              v-model="passwords.current"
              class="mb-4"
              :placeholder="t('userPage.profile.fields.passwordCurrent.placeholder')"
              type="password"
            />
          </div>
          <div>
            <label class="d-block mb-1">{{ t('userPage.profile.fields.password.label') }}</label>
            <TextField
              :disabled="loading || $store.state.ui.busy"
              v-model="passwords.next"
              class="mb-4"
              :placeholder="t('userPage.profile.fields.password.placeholder')"
              type="password"
            />
          </div>
          <div>
            <label class="d-block mb-1">{{ t('userPage.profile.fields.password2.label') }}</label>
            <TextField
              :disabled="loading || $store.state.ui.busy"
              v-model="passwords.confirm"
              class="mb-4"
              :placeholder="t('userPage.profile.fields.password2.placeholder')"
              type="password"
            />
          </div>

        </div>
        <div class="profile-detail-image ml-12 mr-6">
          <label class="d-block mb-2">{{ t('userPage.profile.fields.photo.label') }}</label>
          <div class="position-relative">
            <div class="avtar" :style="{
              backgroundImage: `url(${avatarUrl})`
            }"/>
            <label
              for="avatar-input"
              class="add-profile-image-button"
              :class="{
                loading: loadingAvatar,
                disabled: loadingAvatar || loading
              }"
              :disabled="loadingAvatar || loading"
            >
              <div
                class="button-illusion elevation-2"
                :class="{
                  'bg-primary': !(loadingAvatar || loading),
                  'bg-admin-grey': loadingAvatar || loading
                }"
              >
                <v-icon v-if="loadingAvatar" class="spinner">mdi-loading</v-icon>
                <v-icon v-else>mdi-plus</v-icon>
              </div>
            </label>
            <input :disabled="loadingAvatar || loading" id="avatar-input" type="file" @change="updateAvatar($event)">
          </div>
          <div v-if="$store.state.user.data?.avatar" class="text-center mt-3">
            <a href="#" @click.stop.prevent="deleteAvatar()" class="delete-photo font-size-14">{{ t('userPage.profile.fields.photo.deleteLabel') }}</a>
          </div>
        </div>
        <div class="w-100 text-right">
          <v-btn
            :disabled="loading || loadingAvatar || $store.state.ui.busy"
            :loading="loading"
            class="white--text"
            flat
            color="primary"
            @click="save()"
          >
            <span v-if="!loading">
            {{t('buttons.update')}}
            </span>
            <v-icon v-else class="spinner">mdi-loading</v-icon>
          </v-btn>
        </div>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
#avatar-input {
  display: none;
}
.profile-detail-image{
  .avtar{
    width: 120px;
    background-size: cover;
    background-position: center;
  }
}
.delete-photo{
  opacity: .75;
  text-decoration: underline;
  color: #fff;
  &:hover{
    opacity: 1;
  }
}
.email-field{
  background-color: rgb(var(--v-theme-link-2d));
  color: rgb(var(--v-theme-white));;
  border-radius: 5px;
  font-size: 16px;
  padding: 14px;
}
.add-profile-image-button{
  position: absolute;
  right: 5px;
  bottom: 5px;
  cursor: pointer;


  .button-illusion {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    padding-left: 1px;
  }

  &.loading, &.disabled {
    cursor: inherit;

    svg {
      fill: #ddd !important;
    }
  }

  svg{
    width: 45px;
    height: 45px;
    display: block;
  }
}
</style>
