<script>
import OverlayPage from '@/components/OverlayPage.vue'
import DropdownField from '@/components/fields/Dropdown'
import { Locale } from '@/lib/enums'

import FlagUS from '@/assets/imgs/icons/flags/usa.svg'
import FlagFR from '@/assets/imgs/icons/flags/france.svg'
import FlagES from '@/assets/imgs/icons/flags/spain.svg'

export default {
  name: 'SettingsPage',
  components: {
    OverlayPage,
    DropdownField,
  },
  created() {
    this.$store.state.pc.app.fire('ui:open', true)
  },
  beforeUnmount() {
    this.$store.state.pc.app.fire('ui:open', false)
  },
  data() { return {
    locale: `${this.$store.state.ui.locale}`
  }},
  computed: {
    locales() { return {
      [`${Locale.en}`]: this.$t(`locales.${Locale.en}`),
      [`${Locale.es}`]: this.$t(`locales.${Locale.es}`),
      [`${Locale.fr}`]: this.$t(`locales.${Locale.fr}`)
    }},
    localeFlags() { return {
      [`${Locale.en}`]: FlagUS,
      [`${Locale.es}`]: FlagES,
      [`${Locale.fr}`]: FlagFR,
    }}
  },
  watch: {
    locale(next) {
      const n = parseInt(next)
      if (!Locale[n])
        return
      const nl = Locale[n]
      //console.log('next locale', next, nl)
      this.$i18n.locale = nl
      this.$store.commit('ui/setLocale', nl)
    }
  }
}
</script>

<template>
<OverlayPage @close="$router.push({name:'3DPage'})">
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <label>{{$t('settingsPage.locale')}}</label>
      </v-col>
      <v-col>
        <DropdownField
          v-model="locale"
          :options="locales"
          :icons="localeFlags"
        />
      </v-col>
    </v-row>
    <v-row v-if="$can('admin')">
      <v-col>
        <router-link target="_blank" :to="{name: 'Scheduling'}" class="text-white">
          {{$t('settingsPage.dashboard')}}
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</OverlayPage>
</template>
