<script>
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(RelativeTime)

export default {
  name: 'UserScoreTable',
  computed: {
    table() {
      let list = this.$store.getters['user/score/list']
      list.sort((a,b) => {
        const da = dayjs(a.gameStart)
        const db = dayjs(b.gameStart)
        return da.unix() - db.unix()
      })
      return list
    }
  },
  methods: {
    activityName(aid) {
      const activity = this.$store.getters['collections/activities/byID'](aid)
      if (!activity) return '??'
      return this.$l(activity.content.name)
    },
    ago(time) {
      const d = dayjs.unix(time.seconds)
      return d.fromNow()
    },
  }
}
</script>

<template>
  <div class="flex-grow h-full d-flex flex-column overflow-hidden">
    <div class="user-score d-flex w-100 mt-3 mb-5">
      <div class="user-score-profile-image mr-6">
      <!--
        <v-img
            cover
            width="90"
            height="130"
            lazy-src="https://picsum.photos/id/11/10/6"
            src="https://picsum.photos/id/11/500/300"
          ></v-img>
      </div>
      <div>
      -->
        <div class="user-score-greetings">{{ $t('userPage.files.score.greetings') }}</div>
        <h4>{{ $store.state.user.data.displayName }}</h4>
        <!--
        <div class="d-flex text-center mx-n3 user-score-meta">
          <div class="pa-3">
            <h2 class="mb-2">{{ user.scoresMeta.score }}</h2>
            <span class="text-uppercase">{{ $t('userPage.files.score.metaLabel.score') }}</span>
          </div>
          <div class="pa-3">
            <h2 class="mb-2">{{ user.scoresMeta.correct }}</h2>
            <span class="text-uppercase">{{ $t('userPage.files.score.metaLabel.correct') }}</span>
          </div>
          <div class="pa-3">
            <h2 class="mb-2">{{ user.scoresMeta.wrong }}</h2>
            <span class="text-uppercase">{{ $t('userPage.files.score.metaLabel.wrong') }}</span>
          </div>
        </div>
        -->
      </div>
    </div>
    <div class="flex-grow h-full overflow-auto">
      <div class="user-score-table overflow-x-auto">
        <table class="w-100">
          <thead>
            <tr>
              <th></th>
              <th>{{ $t('userPage.files.score.table.heading.name') }}</th>
              <th class="text-center">{{ $t('userPage.files.score.table.heading.score') }}</th>
              <th>{{ $t('userPage.files.score.table.heading.finishing_time') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in table" :key="row.id">
              <td>
                <v-img
                  class="rounded-circle"
                  cover
                  width="40"
                  height="40"
                  :lazy-src="$store.state.user.data.avatar"
                  :src="$store.state.user.data.avatar"
                ></v-img>
              </td>
              <td>{{ activityName(row.activity) }}</td>
              <td class="text-center">{{ row.score }}</td>
              <td>{{ ago(row.gameStart) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-score-greetings{
  font-size:25px;
  font-weight:600;
}
.user-score-profile-image{
  border-radius: 10px;
  overflow: hidden;
}
.user-score-meta{
  span{
    font-size:14px;
  }
}
.user-score-table{
  th{
    text-align: left;
  }
  td, th{
    padding: 5px 10px;
  }
  tbody td:first-child{
    width:70px;
  }
}
</style>
