<script>
import OverlayPage from '@/components/OverlayPage.vue'
import IconUser from '@/assets/imgs/icons/user-no-avatar.svg'
import IconFiles from '@/assets/imgs/icons/profile-files.svg'
import IconGallery from '@/assets/imgs/icons/profile-gallery.svg'
import IconLogout from '@/assets/imgs/icons/profile-logout.svg'

import FlagUs from '@/assets/imgs/icons/flags/usa.svg'
import FlagFr from '@/assets/imgs/icons/flags/france.svg'
import FlagEs from '@/assets/imgs/icons/flags/spain.svg'
import FlagGb from '@/assets/imgs/icons/flags/uk.svg'

import { Country } from '@/lib/enums'

export default {
  name: 'UserPage',
  components: {
    OverlayPage,
    IconUser,
    IconFiles,
    IconGallery,
    IconLogout,

    FlagUs,
    FlagEs,
    FlagFr,
    FlagGb
  },
  setup() {
    return { Country }
  },
  created() {
    if (this.$store.state.pc.app)
      this.$store.state.pc.app.fire('ui:open', true)
  },
  beforeUnmount() {
    if (this.$store.state.pc.app)
      this.$store.state.pc.app.fire('ui:open', false)
  },
  methods: {
    // retailerName(rid) {
      // const retailer = this.$store.getters['collections/retailers/byID'](rid)
      // return retailer?.name || ''
    // }
  }
}
                //{{retailerName($store.state.user.data?.retailer)}}
</script>

<template>
<OverlayPage @close="$router.push({name:'3DPage'})">
  <div class="user-page-layout d-flex h-full">
    <div class="user-page-sidebar h-full d-flex flex-column flex-shrink-0">
      <div class="d-flex mt-5 mb-10 align-items-center">
        <div class="avtar flex-shrink-0" :style="'background-image:url(' + $store.state.user.data?.avatar + ')'"></div>
        <div class="user-page-sidebar-user-meta ml-4">
          <h2 class="text-truncate">{{$store.state.user.data?.displayName}}</h2>
          <p class="text-truncate mt-1">{{$t(`roles.${$store.state.user.data?.role||2}`)}}</p>
          <div class="text-truncate font-size-14">{{$store.state.user.data?.email}}</div>
          <div class="text-truncate font-size-14">
            <div class="d-flex">
              <FlagUs v-if="$store.state.user.data?.retailerCountry === Country.US" class="mr-2 flag-icon"/>
              <FlagFr v-if="$store.state.user.data?.retailerCountry === Country.FR" class="mr-2 flag-icon"/>
              <FlagEs v-if="$store.state.user.data?.retailerCountry === Country.ES" class="mr-2 flag-icon"/>
              <FlagGb v-if="$store.state.user.data?.retailerCountry === Country.GB" class="mr-2 flag-icon"/>
              <div v-if="$store.state.user.data" class="d-flex flex-column justify-center">
                {{$store.state.user.data?.company}}
              </div>
            </div>
 


          </div>
        </div>
      </div>
      <div class="flex-grow-1 h-full overflow-y-auto custom-scrollbar user-page-sidebar-links-wrapper">
        <div class="d-flex flex-column">
          <router-link class="text-link-2d font-size-15 d-flex align-items-center" :to="{ name: 'Profile' }">
            <div class="profile-menu-icon">
              <IconUser fill="#fff" style="position: relative; top: -1px;"/>
            </div>
            <div>
              <h3>{{$t('userPage.sidebarLinks.profile.label')}}</h3>
              <p>{{$t('userPage.sidebarLinks.profile.supportText')}}</p>
            </div>
          </router-link>
          <router-link class="text-link-2d font-size-15 d-flex align-items-center" :to="{ name: 'Files' }">
            <div class="profile-menu-icon">
              <IconFiles style="width: 40px; height: 40px; position: relative; top: 7px;" />
            </div>
            <div>
              <h3>{{$t('userPage.sidebarLinks.files.label')}}</h3>
              <p>{{$t('userPage.sidebarLinks.files.supportText')}}</p>
            </div>
          </router-link>
          <router-link class="text-link-2d font-size-15 d-flex align-items-center" :to="{ name: 'Gallery' }">
            <div class="profile-menu-icon">
              <IconGallery style="width: 40px; height: 40px; position: relative; top: 7px;" />
            </div>
            <div>
              <h3>{{$t('userPage.sidebarLinks.gallery.label')}}</h3>
              <p>{{$t('userPage.sidebarLinks.gallery.supportText')}}</p>
            </div>
          </router-link>
          <a class="text-link-2d font-size-15 d-flex align-items-center" href="#" @click.stop.prevent="$store.dispatch('user/logout')">
            <div class="profile-menu-icon">
              <IconLogout style="width: 40px; height: 40px; position: relative; top: 7px;" />
            </div>
            <div>
              <h3>{{$t('userPage.sidebarLinks.logout.label')}}</h3>
              <p>{{$t('userPage.sidebarLinks.logout.supportText')}}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="user-page-content flex-grow-1 flex-shrink-1 h-full">
      <router-view name="profileView"></router-view>
    </div>
  </div>
</OverlayPage>
</template>

<style lang="scss" scoped>
.user-page-sidebar{
  width: 400px;
  min-width: 350px;
  max-width: 450px;
  border-right: 2px solid rgb(var(--v-theme-grey-200));
  margin-right: 40px;
  .avtar{
    width:90px;
    background-size: cover;
    background-position: center;
  }
  &-user-meta{
    p{
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
    }
    a{
      color: #ddd;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  &-links-wrapper{
    margin-left: -20px;
    @media(min-width:1366px){
      margin-left: -30px;
    }
    a{
      padding:20px 40px;
      text-decoration: none;
      color: #fff;
      &:hover{
        background-color: rgb(var(--v-theme-primary));
      }
      &.router-link-active{
        background-color: rgb(var(--v-theme-primary));
      }
    }

    .profile-menu-icon {
      border: 1px solid #fff;
      width: 58px;
      height: 58px;
      border-radius: 29px;
      margin-right: 20px;
      text-align: center;
    }
  }
}
.user-page-content{
  
}
</style>
