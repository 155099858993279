<script>
import { MIME, MediaStatus } from '@/lib/enums'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(RelativeTime)

export default {
  name: 'FilesListItem',
  props: {
    file: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  methods:{
    fileSizeInMB(size){
      return (size/1048576).toFixed(2) + "MB";
    },
    ago(file) {
      const d = dayjs.unix(file.createdAt.seconds)
      return d.fromNow()
    },
    iconText(mime) {
      const type = MIME[mime]
      return type.split('/').pop()
    }
  },
  computed: {
    isAccessible() { return this.file?.status === MediaStatus.Success },
    isProcessing() { return this.file?.status === MediaStatus.Processing },
    isError() { return this.file?.status === MediaStatus.Error },
    statusClass() { return (MediaStatus[this.file?.status] || 'processing').toLowerCase() }
  }
}
</script>

<template>
<div class="position-relative files-list-item-wrappper">
  <div :class="`files-list-item-${statusClass}`" class="files-list-item align-items-center">
    <span class="files-list-item-dot" :class="isActive ? 'active' : ''"></span>
    <span class="files-list-item-icon d-flex justify-content-center align-items-center text-uppercase mx-6">
      <v-icon v-if="isProcessing" class="spinner" size="sm">mdi-loading</v-icon>
      <v-icon v-if="isError" class="error" size="sm">mdi-alert</v-icon>
      <!-- {{ iconText(file.MIME) }} -->
      <svg v-if="isAccessible && file.MIME === 5" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
        <rect x="3" y="6" width="12" height="12" rx="2" />
      </svg>
      <svg v-if="isAccessible && file.MIME === 4" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <rect x="4" y="13" rx="2" width="5" height="7" />
        <rect x="15" y="13" rx="2" width="5" height="7" />
        <path d="M4 15v-3a8 8 0 0 1 16 0v3" />
      </svg>
      <svg v-if="isAccessible && file.MIME === 3" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <line x1="15" y1="8" x2="15.01" y2="8" />
        <rect x="4" y="4" width="16" height="16" rx="3" />
        <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
        <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
      </svg>
      <svg v-if="isAccessible && file.MIME === 2" height="32" viewBox="0 0 32 32" width="32">
        <path d="M21 13v-3l-6-7H4.003A2.005 2.005 0 0 0 2 5.007v22.986A2 2 0 0 0 3.997 30h15.006A1.999 1.999 0 0 0 21 28.01V26h7.993A3.003 3.003 0 0 0 32 23.001V16A2.996 2.996 0 0 0 28.993 13H21Zm-1 13v2.007A.997.997 0 0 1 19 29H4c-.545 0-1-.446-1-.995V4.995c0-.54.446-.995.996-.995H14v4.994c0 1.12.894 2.006 1.998 2.006H20v2h-7.993A3.003 3.003 0 0 0 9 15.999V23A2.996 2.996 0 0 0 12.007 26H20ZM15 4.5v4.491c0 .557.45 1.009.997 1.009H19.7L15 4.5ZM11.995 14C10.893 14 10 14.9 10 15.992v7.016A2 2 0 0 0 11.995 25h17.01C30.107 25 31 24.1 31 23.008v-7.016A2 2 0 0 0 29.005 14h-17.01ZM25 19v-2h4v-1h-5v7h1v-3h3v-1h-3Zm-13-1v5h1v-3h1.995a2 2 0 1 0 0-4H12v2Zm1-1v2h2.001A.997.997 0 0 0 16 18c0-.552-.443-1-.999-1H13Zm5-1v7h2.995A1.998 1.998 0 0 0 23 20.994v-2.988A2.003 2.003 0 0 0 20.995 16H18Zm1 1v5h2.001A.997.997 0 0 0 22 21v-3c0-.552-.443-1-.999-1H19Z" fill="currentColor" fill-rule="evenodd"/>
      </svg>
    </span>
    <div class="files-list-item-meta">
      <div class="d-flex">
        <!--
        <a
          v-if="file.isDownloadable"
          :href="file.details.url.split('?').shift()"
          class="mr-1 a-download"
          target="_blank"
          :download="file.details.filename"
        >
          <v-icon>mdi-download</v-icon>
        </a>
        -->
   
        <p class="text-truncate">{{ file.details.filename }}</p>
      </div>
      <span>{{ fileSizeInMB(file.details.size) }}</span>, <span>{{ ago(file) }}</span>
    </div>
  </div>
  <span class="files-list-item-connecter"></span>
</div>
</template>

<style scoped lang="scss">
/**/
.files-list-item {
  position: relative;
  padding-top:10px;
  padding-bottom:10px;
  display:grid;
  grid-template-columns: 14px 88px calc(100% - 102px);

  .a-download {
    color: #fff;
    text-decoration: none;
  }
  > *{
    opacity: .75;
  }
  &:hover > *{
    opacity: 1;
  }

  &-processing {

  }
  &-success {
    cursor: pointer;
  }
  &-error {

  }
  &-dot{
    width:14px;
    height:14px;
    border:2px solid #fff;
    border-radius: 50%;
    flex-shrink: 0;
    &.active{
      background-color: #fff;
      ~ *{
        opacity: 1;
      }
    }
  }
  &-connecter{
    position: absolute;
    left:6px;
    top:50%;
    transform:translatey(7px);
    width:2px;
    height: calc(100% - 14px);
    background-color: rgb(var(--v-theme-grey-200));
  }
  &-icon{
    flex-shrink: 0;
    width:40px;
    height:40px;
    background-color: rgb(var(--v-theme-grey-200));
    border-radius:10px;
    color:#fff;
    font-weight:600;
    font-size: 12px;  
  }
  &-meta{
    p{
      line-height: normal;
      margin-bottom: -4px;
    }
    > span{
      font-size: 12px;
      opacity:.8;
    }
  }
}
.files-list-item-wrappper{
  &:last-child{
    .files-list-item-connecter{
      display: none;
    }
  }
}
</style>
