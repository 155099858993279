<script>
import IconClose from '@/assets/imgs/icons/close.svg'
import IconFullScreen from '@/assets/imgs/icons/icon-full-screen.svg'
import IconFullScreenClose from '@/assets/imgs/icons/icon-full-screen-close.svg'

import waitFor from '@/utils/waitFor'

export default {
  name: 'OverlayPage',
  props: ['downloadURL','bookmark', 'fullscreen'],
  emits: [
    'close'
  ],
  components: {
    IconClose,
    IconFullScreen,
    IconFullScreenClose
  },
  data(){
    return{
      isFullscreen: !!this.fullscreen
    }
  },
  computed: {
    bookmarked() {
      if (!this.bookmark)
        return false
      const list = this.$store.getters['user/media/list']
      return !!list.find(el => el.id === this.bookmark.fid)
    }
  },
  async created(){
    await waitFor(0);
    let overlayPage = this.$refs.overlayPage;
    if(overlayPage && typeof overlayPage.addEventListener === 'function'){
      overlayPage.addEventListener('mousedown', function(evt) {
        evt.stopPropagation();
      });
    }
    let close = this.$refs.close;
    if(close && typeof close.addEventListener === 'function'){
      close.addEventListener('mousedown', function(evt) {
        evt.stopPropagation();
      });
    }
    let addToBriefcase = this.$refs.addToBriefcase;
    if(addToBriefcase && typeof addToBriefcase.addEventListener === 'function'){
      addToBriefcase.addEventListener('mousedown', function(evt) {
        evt.stopPropagation();
      });
    } 
    let fullscreen = this.$refs.fullscreen;
    if(fullscreen && typeof fullscreen.addEventListener === 'function'){
      fullscreen.addEventListener('mousedown', function(evt) {
        evt.stopPropagation();
      });
    }
    let download = this.$refs.download;
    if(download && typeof download.addEventListener === 'function'){
      download.addEventListener('mousedown', function(evt) {
        evt.stopPropagation();
      });
    }
  },
  methods:{
    toggleFullscreen(){
      this.isFullscreen = !this.isFullscreen;
    },
    bookmarkMe() {
      if (this.bookmarked)
        this.$store.dispatch('user/media/delete', this.bookmark.fid)
      else
        this.$store.dispatch('user/bookmarkMedia',this.bookmark)
    }
  },
  watch:{
    fullscreen(){
      this.isFullscreen = this.fullscreen;
    }
  }
}
</script>

<template>
  <div
    class="overlay-page"
    ref="overlayPage"
    :class="{
      'd-hidden': $store.state.ui.leaderboardOpen,
      'overlay-page-fullscreen': isFullscreen
    }"
  >
    <button @click="$emit('close')" class="close-button" ref="close">
      <IconClose/>
    </button>
    <slot/>
    <div id="overlay-controls" class="controls d-flex">
      <button class="mr-3 add-to-briefcase-button top-menu-button" v-if="bookmark" @click="bookmarkMe" ref="addToBriefcase">
        <v-icon v-if="bookmarked">mdi-heart</v-icon>
        <v-icon v-else>mdi-heart-outline</v-icon>
        <span v-if="bookmarked" class="shadow">{{ $t('hoverLabels.alreadyInBriefcase') }}</span>
        <span v-else class="shadow">{{ $t('hoverLabels.addToBriefcase') }}</span>
      </button>
      <a
        ref="download"
        v-if="downloadURL?.url && downloadURL?.filename"
        :href="downloadURL.url"
        class="mr-3 add-to-briefcase-button"
        target="_blank"
        :download="downloadURL.filename"
      >
        <v-icon>mdi-download</v-icon>
        <span class="shadow">{{ $t('hoverLabels.download') }}</span>
      </a>
      <button @click="toggleFullscreen" ref="fullscreen">
        <IconFullScreen v-if="!isFullscreen"/>
        <IconFullScreenClose v-else/>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.d-hidden {
  visibility: hidden;
}
.add-to-briefcase-button{
  position: relative;
  span{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 20px);
    background-color: rgb(var(--v-theme-white));
    color: rgb(var(--v-theme-black));
    padding: 4px 10px;
    font-size: 14px;
    border-radius: 6px;
    white-space: nowrap;
    opacity: 0;
    transition: all .5s;
    pointer-events: none;
    box-shadow: 0 0 4px 0 rgba(var(--v-theme-black),.5);
  }
  &:hover{
    span{
      opacity: 1;
      top: calc(100% + 10px);
    }
  }
}
.overlay-page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  background: rgba(0,0,0,0.9);
  color: #fff;
  padding: 40px 20px;
  z-index:1001;
  @media(min-width:1366px){
    top: 80px;
    bottom: 80px;
    left: 200px;
    right: 200px;
    padding-left: 30px;
    padding-right: 30px;
    &.overlay-page-fullscreen{
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .close-button {
    position: absolute;
    top: 13px;
    right: 15px;
    &:hover{
      svg g{
        stroke:#919596;    
      }
    }
  }

  .controls {
    position: absolute;    
    top: 10px;
    right: 50px;
    button{
      display: none;
      @media(min-width:1366px){
      button{
          display: block;
        }  
      }
    }
    button, .top-menu-button {
      display: block;
      color:  #797d7e;
      &:hover{
        color:#919596;    
        svg{
          stroke:#919596;    
        }
      }
    }
    svg{
      display: block;
      stroke:  #797d7e;
    }
    a {
      color:  #797d7e;
      text-decoration: none;

      &:hover{
        color:#919596;    
      }
    }
  }
}
</style>
