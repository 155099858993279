<script>
import FilePreview from '@/components/FilePreview'
export default {
  name: 'ContentActivity',
  props: ['content', 'files', 'activity'],
  emits: ['update:downloadURL', 'update:bookmark'],
  data: () => ({
    pausedBackMusic: false
  }),
  components: {
    FilePreview
  },
  created() {
    //console.log('!!!', this.content, this.activity)
  },
  beforeUnmount() {
    if (this.pausedBackMusic)
      this.$store.commit('ui/unpausePlayer')
  },
  computed: {
    fid() {
      if (!this.content.content)
        return null
      const fid = this.$l(this.content.content)
      if (!fid || fid === '' || !this.files[fid])
        return null
      return fid
    },
    media() {
      return this.files[this.fid] || null
    },
    isVideo() {
      if (!this.media)
        return false
      const fmt = this.media.details.originalFormat || this.media.details.originFormat || ''
      return fmt.startsWith('video')
    }
  },
  watch: {
    media(next) {
      if (!next)
        return
      /*
      this.$store.dispatch('user/bookmarkMedia',{
        activity: this.activity,
        media: next,
        isDownloadable: this.content.isDownloadable,
        fid: this.fid
      })
      */
      //console.log('pauseme?', this.media)
      if (!this.pausedBackMusic) {
        //console.log('pauseme!')
        this.pausedBackMusic = true
        this.$store.commit('ui/pausePlayer')
      }
      this.$emit('update:bookmark', {
        activity: this.activity,
        media: next,
        isDownloadable: this.content.isDownloadable,
        fid: this.fid
      })
      /*
      if (!this.content.isDownloadable)
        return
      this.$emit('update:downloadURL', {
        filename: next.details.filename,
        url: next.details.url
      })
      */
    }
  }
}
</script>

<template>
<div class="h-full">
  <FilePreview
    :file="media"
    mode="fit"
    :displayName="isVideo ? $l(content.name) : ''"
  />
</div>
</template>

