<script>

import { Scene } from '@/lib/enums'

export default {
  name: "Map",
  methods: {
    uiButton(scene, navigate) {
      const quizId = this.$store.state.scene.data.quiz
      const userScores = this.$store.getters['user/score/list']

      // console.log('check for last quizz here!', quizId, this.$store.state.scene.name)

      let ok = false
      if (!quizId || quizId === '') {
        ok = true
      } else {
        const quiz = this.$store.getters['collections/activities/byID'](quizId)
        if (!quiz) {
          ok = true
        } else {
          const max = quiz.content.scores.repeats
          const played = userScores.filter(score => score.activity === quizId).length
          ok = played >= max
        }
      }

      if (ok) {
        navigate()
      } else {
        this.$store.commit('scene/setRedirectOnActionClose',{
          name: '3DPage',
          params: { scene }
        })
        this.$router.push({
          name: 'ActivityOverlay',
          params: { id: quizId }
        })
      }
    }
  },
  setup: () => ({ Scene })
}
</script>

<template>
  <div class="map-container d-flex justyfy-center align-items-center">
    <div class="map-row h-full w-100">
      <div class="map-sidebar">

      </div>
      <div class="map-grid">
        <div class="map-grid-row">
          <div class="map-grid-content">
            <div class="map-grid-content-grid map-grid-content-grid-3">
              <div class="map-grid-content-grid-box d-flex justify-center align-items-center">
                <router-link
                    custom
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    :to="{name: '3DPage', params: { scene : 'pool' }}"
                  >
                    <a :href="href" @click.stop.prevent="uiButton('pool', navigate)"
                    :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
                    >
                      <div class="reverse-skew">
                        <v-img src="@/assets/imgs/icons/map/Pool.png"/>
                        <span>{{$t(`scenes.${Scene.Pool}`)}}</span>
                      </div>
                    </a>
                  </router-link>
              </div>
              <div class="map-grid-content-grid-box d-flex justify-center align-items-center">
                <router-link
                    custom
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    :to="{name: '3DPage', params: { scene : 'store' }}"
                  >
                    <a :href="href" @click.stop.prevent="uiButton('store', navigate)"
                    :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
                    >
                      <div class="reverse-skew">
                        <v-img src="@/assets/imgs/icons/map/Store.png"/>
                        <span>{{$t(`scenes.${Scene.Store}`)}}</span>
                      </div>
                    </a>
                  </router-link>
              </div>
              <div class="map-grid-content-grid-box d-flex justify-center align-items-center">
                <router-link
                    custom
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    :to="{name: '3DPage', params: { scene : 'apartment' }}"
                  >
                    <a :href="href" @click.stop.prevent="uiButton('apartment', navigate)"
                    :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
                    >
                      <div class="reverse-skew">
                        <v-img src="@/assets/imgs/icons/map/Apt.png"/>
                        <span>{{$t(`scenes.${Scene.Apartment}`)}}</span>
                      </div>
                    </a>
                  </router-link>
              </div>
            </div>
          </div>
          <div class="map-grid-row-label">
            {{$t('activity.map.levelX',{num: 3})}}
          </div>
        </div>
        <div class="map-grid-row">
          <div class="map-grid-content">
            <div class="map-grid-content-grid">
              <div class="map-grid-content-grid-box d-flex justify-center align-items-center">
                <router-link
                    custom
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    :to="{name: '3DPage', params: { scene : 'loft' }}"
                  >
                    <a :href="href" @click.stop.prevent="uiButton('loft', navigate)"
                    :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
                    >
                      <div class="reverse-skew">
                        <v-img src="@/assets/imgs/icons/map/Loft.png"/>
                        <span>{{$t(`scenes.${Scene.Loft}`)}}</span>
                      </div>
                    </a>
                  </router-link>
              </div>
              <div class="map-grid-content-grid-box d-flex justify-center align-items-center">
                <router-link
                    custom
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    :to="{name: '3DPage', params: { scene : 'office' }}"
                  >
                    <a :href="href" @click.stop.prevent="uiButton('office', navigate)"
                    :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
                    >
                      <div class="reverse-skew">
                        <v-img src="@/assets/imgs/icons/map/Classroom.png"/>
                        <span>{{$t(`scenes.${Scene.Office}`)}}</span>
                      </div>
                    </a>
                  </router-link>
              </div>
            </div>
          </div>
          <div class="map-grid-row-label">
            {{$t('activity.map.levelX',{num: 2})}}
          </div>
        </div>
        <div class="map-grid-row">
          <div class="map-grid-content">
            <div class="map-grid-content-grid">
              <div class="map-grid-content-grid-box d-flex justify-center align-items-center">
                  <router-link
                    custom
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    :to="{name: '3DPage', params: { scene : 'campus' }}"
                  >
                    <a :href="href" @click.stop.prevent="uiButton('campus', navigate)"
                    :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
                    >
                      <div class="reverse-skew">
                        <v-img src="@/assets/imgs/icons/map/Campus.png"/>
                        <span>{{$t(`scenes.${Scene.Campus}`)}}</span>
                      </div>
                    </a>
                  </router-link>
              </div>
              <div class="map-grid-content-grid-box d-flex justify-center align-items-center">
                  <router-link
                    custom
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    :to="{name: '3DPage', params: { scene : 'warehouse' }}"
                  >
                    <a :href="href" @click.stop.prevent="uiButton('warehouse', navigate)"
                    :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
                    >
                      <div class="reverse-skew">
                        <v-img src="@/assets/imgs/icons/map/Warehouse.png"/>
                        <span>{{$t(`scenes.${Scene.Warehouse}`)}}</span>
                      </div>
                    </a>
                  </router-link>
              </div>
            </div>
          </div>
          <div class="map-grid-row-label">
            {{$t('activity.map.levelX',{num: 1})}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.overlay-temp{
  padding: 40px;
}
.map-container{
  background-color: #184e63;
  padding: 40px;
  outline: 1px solid #fff;
  outline-offset: -20px;
  min-height: 100%;;
}
.map-row{
  display: grid;
  grid-template-columns: 0 1fr;
  grid-gap: 30px;
}
.map-grid{
  display: grid;
  grid-template-rows: repeat(3, 0.5fr);
  grid-gap: 40px;
  &-row{
    padding-right: 100px;
    display: grid;
    grid-template-columns: 1fr 150px;
    transform-origin: bottom left;
    //transform: skewX(-20deg);
  }
  &-content{
    padding: 15px;
    border: 1px solid #fff;
    transform: skewX(-20deg);
    &-grid{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
      height: 100%;
      &-box{
        border: 1px solid #fff;
        a{
          padding:15px;
          display: flex;
          width:100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          color:#fff;
          text-decoration: none;
          transition: all .5s;
          &:not(.router-link-active):hover{
            box-shadow: 0 0 8px 4px rgba(255,255,255,.7);
          }
          &.router-link-active{
            pointer-events: none;
            background-color: rgba(255,255,255,.05);
          }
        }
      }
      &-3{
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        .map-grid-content-grid-box:first-child{
          grid-column: 1/2;
          grid-row: 1/3;
        }
        .map-grid-content-grid-box:nth-child(02){
          grid-column: 2/3;
          grid-row: 1/2;
        }
        .map-grid-content-grid-box:nth-child(02){
          grid-column: 2/3;
          grid-row: 2/3;
        }
      }
    }
  }
}
.reverse-skew {
  //transform-origin: bottom left;
  display: flex;
  transform: skewX(20deg);

  .v-img {
    width: 50px;
    height: 50px;
  }

  span {
    padding-top: 15px;
    margin-left: 10px;
  }
}
.map-grid-row-label{
  font-size: 24px;
  padding-left: 70px;
  white-space: nowrap;
  align-self: flex-end;
  padding-bottom: 20px;
}
</style>
