<script>

import FilePreview from '@/components/FilePreview'
import IconClose from '@/assets/imgs/icons/close.svg'

export default {
  name: 'NotificationPopup',
  components:{
    FilePreview,
    IconClose
  },
  emits: ['close'],
  data(){
    return{
      media: null
    }
  },
  methods:{
    notificationPopupclose(){
      this.$emit('close');
    }
  }
}
</script>

<template>
<div class="notification-dialog-overlay d-flex justify-center align-items-center">
  <div class="notification-dialog border rounded-lg w-100">
    <div class="notification-dialog-header py-3 px-5 position-relative d-flex justify-content-between align-items-center">
      Notification Title
      <button class="notification-dialog-close-button flex-shrink-0 ml-2" @click="notificationPopupclose">
        <IconClose/>
      </button>
    </div>
    <div class="notification-dialog-body pa-5">
      <FilePreview
        :file="media"
      />
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.notification-dialog-overlay{
  position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 2000;
}
.notification-dialog{
  pointer-events: auto;
  background-color: rgba(var(--v-theme-black), .9);
  max-width: 600px;
  color: rgb(var(--v-theme-white));
  &-header{
    border-bottom: 1px solid rgba(var(--v-theme-white), .2);
  }
}
.notification-dialog-close-button{
  svg{
    display: block;
  }
}
</style>
