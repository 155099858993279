<script>
import * as Sentry from "@sentry/vue";

import fileUpload from '@/utils/fileUpload'
import MosaicImage from '@/assets/imgs/Facebook-Mosiac.png'
//import image64ToBuffer from '@/utils/image64ToBuffer'

export default {
  name: 'MosaicActivity',
  props: ['content', 'activity','collection', 'files'],
  emits: ['close'],
  data(){
    return{
      webcam: false,
      fromWebcame: false,
      imagePreview: false,
      previewImageBuffer:null,
      previewImageBlob: null,
      stream:null,
      video:null
    }
  },
  //created(){
    //console.log(this.files)
  //},
  setup: () => ({ MosaicImage }),
  methods:{
    async captureStart(){
      this.webcam = true;
      this.imagePreview = this.fromWebcame = false;
      await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true
      })
      .then(stream => {
        window.localStream = this.stream = stream;
      })
      .catch((err) => {
        Sentry.captureException(err)
        console.log(err);
      });
      //this.stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false })
    },
    toBuffer(canvas){
      return new Promise(accept => {
        canvas.toBlob(blob => {
          blob.arrayBuffer().then(result => {
            accept(result)
          })
        }, 'image/jpeg', 0.95);
      })
    },
    async capture(){
      // Capture screen logic
      this.previewImageBlob = null;
      this.video = this.$refs.video;
      var canvas = this.$refs.canvas;
      var canvas2 = this.$refs.canvas2;

      canvas.getContext('2d').drawImage(this.video, 0, 0, canvas.width, canvas.height);
      var canvasDraw = canvas2.getContext('2d');
      canvasDraw.translate(canvas.width, 0);
      canvasDraw.scale(-1, 1);
      canvasDraw.drawImage(canvas, 0, 0);
      let image_data_url = canvas2.toDataURL('image/jpeg');
      this.previewImageBuffer = await this.toBuffer(canvas2);
      //console.log(this.previewImageBuffer);
      this.previewImageBlob = image_data_url;

      window.localStream.getVideoTracks()[0].stop();

      // move to preview screen
      this.stream = null;
      this.webcam = false;
      this.imagePreview = this.fromWebcame = true;
    },
    gotodropzone(){
      window.localStream.getVideoTracks()[0].stop();
      this.previewImageBlob = null;
      this.webcam = this.imagePreview = this.fromWebcame = false;
    },
    upload(e){
      this.imagePreview = true;
      var reader = new FileReader();
      reader.onload = () => {
        this.previewImageBlob = reader.result;
      }
      reader.readAsDataURL(e.target.files[0]);
      var reader2 = new FileReader();
      reader2.onload = () => {
        this.previewImageBuffer = reader2.result
      }
      reader2.readAsArrayBuffer(e.target.files[0]);
    },
    async doSubmit() {
      // console.log('submitting', this.previewImageBuffer)
      this.$store.commit('ui/setBusy', true)
      const url = await fileUpload({
        file:{
          name: "Mosaic Image",
          type: "image/jpeg",
          size: this.previewImageBuffer.byteLength,
        },
        buffer: this.previewImageBuffer,
        collection: this.collection,
        storage: process.env.VUE_APP_FIREBASE_STORAGE_ACTIVITY_UPLOAD_BUCKET,
        refPrefix: '/' + this.activity + '/',
        locale: this.$store.state.user.data.locale,
        createdBy: this.$store.state.user.data.uid
      })
      const uploadedURL = await this.getMosaicImageUrl(url);
      //console.log({uploadedURL});
      if (this.$store.state.pc.app)
        this.$store.state.pc.app.fire('3d:setTexture', uploadedURL)
      this.$store.commit('ui/setBusy', false)
      this.$emit('close')
    },
    getMosaicImageUrl(id){
      return new Promise(accept => {
        const ref = this.collection.doc(id)
        const sub = ref.onSnapshot(snap => {
        const media = snap.data()
        // console.log('uploaded media', media)
        if (media?.details?.url?.length) {
            sub()
            accept(media.details.url)
          }
        })
      })
    }
  },
  beforeUnmount() {
    if(window.localStream){
      window.localStream.getVideoTracks()[0].stop();
    }
    this.webcam = this.fromWebcame = this.imagePreview = false;
    this.previewImageBlob = this.stream = this.video = null;
  }
}
</script>

<template>
<div class="h-full overflow-y-auto custom-scrollbar">
  <div class="d-flex justify-center align-items-center w-full" style="min-height:100%">
    <div class="w-100 text-center mosaic-upload-section">

      <!-- dropzone -->
      <div class="" v-if="!webcam && !imagePreview">
        <h2 class="mb-4">{{ $l(content.name) }}</h2>
        <v-img fluid :src="MosaicImage"/>
        <!--
        <p class="mt-3" v-if="content.description" v-html="$l(content.description)"></p>
        <label class="drop-zone mx-auto mt-8 position-relative">
          <input type="file" accept="image/*" @change="upload($event)" />
          <svg class="mx-auto" width="100" height="100" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
            <polyline points="9 15 12 12 15 15" />
            <line x1="12" y1="12" x2="12" y2="21" />
          </svg>
          <div class="mt-6">
            <h3 v-html="$t('mosaicOverlay.dropzone.placeholder')"></h3>
          </div>
        </label>
        -->
        <div class="mt-8 d-flex justify-content-center">
          <label class="v-btn v-theme--light bg-primary v-btn--density-default elevation-0 v-btn--size-default v-btn--variant-contained ml-4">
            <input class="d-none" type="file" accept="image/*" @change="upload($event)" />
          {{ $t('buttons.upload') }}
          </label>
          <div class="mx-6 pt-1">{{ $t('mosaicOverlay.dropzone.or') }}</div>
          <v-btn
            flat
            elevation="0"
            color="primary"
            class=""
            :disabled="$store.state.ui.busy"
            @click="captureStart"
          >
          {{ $t('buttons.useWebcame') }}
          </v-btn>
        </div>
      </div>

      <!-- Capture Image -->
      <div class="" v-if="webcam && !imagePreview">
        <video id="mosaic-video" ref="video" :srcObject.prop="stream" width="320" height="240" autoplay></video>
        <canvas style="display:none" ref="canvas" width="320" height="240"></canvas>
        <canvas style="display:none" ref="canvas2" width="320" height="240"></canvas>
        <div class="mt-8">
          <v-btn
            flat
            elevation="0"
            color="primary"
            :disabled="$store.state.ui.busy"
            @click="capture"
          >
          {{ $t('buttons.capture') }}
          </v-btn>
          <p class="my-4">{{ $t('mosaicOverlay.dropzone.or') }}</p>
          <v-btn
            flat
            elevation="0"
            color="white"
            :disabled="$store.state.ui.busy"
            @click="gotodropzone"
          >
          {{ $t('buttons.uploadImage') }}
          </v-btn>
        </div>
      </div>

      <!-- preview uploaded image before upload -->
      <div v-if="imagePreview">
        <div class="d-flex align-items-center justify-center">
          <img :src="previewImageBlob" alt="image" class="flex-shrink-0 preview-image" />
          <div class="ml-10 d-flex flex-column">
            <v-btn
              v-if="fromWebcame"
              flat
              variant="outlined"
              elevation="0"
              color="primary"
              :disabled="$store.state.ui.busy"
              @click="captureStart"
              class="mb-4"
            >
            {{ $t('buttons.retake') }}
            </v-btn>
            <v-btn
              v-if="!fromWebcame"
              flat
              variant="outlined"
              elevation="0"
              color="primary"
              :disabled="$store.state.ui.busy"
              class="mb-4"
              @click="gotodropzone"
            >
            {{ $t('buttons.reupload') }}
            </v-btn>
            <v-btn
              v-if="!fromWebcame"
              flat
              elevation="0"
              color="primary"
              :disabled="$store.state.ui.busy"
              @click="captureStart"
              class="mb-4"
            >
            {{ $t('buttons.useWebcame') }}
            </v-btn>
            <v-btn
              flat
              elevation="0"
              color="primary"
              :disabled="$store.state.ui.busy"
              @click="doSubmit"
            >
            {{ $t('buttons.submit') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>    
  </div>
</div>
</template>

<style lang="scss">
.mosaic-upload-section{
  padding: 0 100px;
}
.drop-zone{
  border: 1px dashed rgb(var(--v-theme-white));
  padding: 50px;
  display: block;
  cursor: pointer;
  input[type="file"]{
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  b{
    color:rgb(var(--v-theme-primary));
    text-decoration:underline;
  }
}
#mosaic-video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  margin:0 auto;
}
.preview-image{
  max-width:240px;
}
</style>
