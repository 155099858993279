<script>
import OverlayPage from '@/components/OverlayPage.vue'
import ContentOverlay from '@/components/activity/Content'
import ScreenOverlay from '@/components/activity/Screen'
import ScormOverlay from '@/components/activity/Scorm'
import MosaicOverlay from '@/components/activity/Mosaic'
import Quiz2DOverlay from '@/components/activity/Quizz2D'
import MapOverlay from '@/components/activity/Map'
import SessionMapOverlay from '@/components/activity/SessionMap'
import UrlRedirectOverlay from '@/components/activity/UrlRedirect'

import { ActivityType } from '@/lib/enums'

export default {
  name: 'ActivityOverlayPage',
  inject: ['pc'],
  components: {
    OverlayPage,
    ContentOverlay,
    ScormOverlay,
    Quiz2DOverlay,
    MapOverlay,
    SessionMapOverlay,
    UrlRedirectOverlay,
    MosaicOverlay,
    ScreenOverlay
  },
  data() {
    return {
      activity: null,
      mediaCollection:null,
      files: {},
      scores: {},
      scoresSub: null,
      downloadURL: null,
      bookmark: null,
      fullscreen:false,
      participant: null
    }
  },
  async created() {
    this.$store.state.pc.app?.fire('ui:open', true)
    await this.reload()
    const pinfo = await this.$store.dispatch('scene/activityEnter', this.activity)
    this.participant = pinfo
    //console.log('activity enter!', this.participant)
    if(this.activity?.type === ActivityType.Screen ){
      this.fullscreen = true;
    }
  },
  beforeUnmount() {
    this.$store.state.pc.app?.fire('ui:open', false)
    if (this.participant)
      this.$store.dispatch('scene/activityLeave', this.participant)
    if (this.scoresSub)
      this.scoresSub()
    if (this.$store.state.scene.redirectOnActionClose) {
      this.$router.push(this.$store.state.scene.redirectOnActionClose)
      this.$store.commit('scene/setRedirectOnActionClose', null)
    }
  },
  setup: () => ({ ActivityType }),
  methods: {
    async reload() {
      this.activity = this.$store.getters['collections/activities/byID'](this.$route.params.id)
      const ref = await this.$store.getters['collections/activities/docRef'](this.$route.params.id)
      this.mediaCollection = ref.collection('media');
      const snap = await ref.collection('media').get()
      const docs = {}
      snap.forEach(rec => {
        const doc = rec.data()
        doc.id = rec.id
        docs[doc.id] = doc
      })
      this.files = docs
      //console.log('files', docs)
      this.scores = await this.$store.dispatch('collections/getActivityLeaderboard', this.activity)
    },
    setDownloadURL(url) {
      this.downloadURL = url
    },
    setBookmark(data) {
      this.bookmark = data
    },
    setScores(score) {
      console.log('setScores', score)
      if (!this.participant)
        return
      this.participant.score = score
    }
  },
  computed: {
    isVideo() {
      if (this.activity && !this.activity.content)
        return false
      //console.log('isVideo?::fid', fid)
      const fid = this.$l(this.activity.content)
      if (!fid || fid === '' || !this.files[fid])
        return false

      //console.log('isVideo?::fid', this.files[fid])
      const fmt = this.files[fid].details.originalFormat || this.files[fid].details.originFormat || ''
      const ret = fmt.startsWith('video')
      //console.log('isVideo?::fid::ret', this.files[fid], ret)
      return ret
    }
  },
  watch: {
    '$route.params.id'() {
      this.reload()
    }
  }
}
</script>

<template>
<OverlayPage
  class="activity-overlay"
  :class="{'d-hidden': activity.type === ActivityType.UrlRedirect}"
  @close="$router.push({name:'3DPage'})"
  :downloadURL="downloadURL"
  :bookmark="bookmark"
  :fullscreen="fullscreen"
>
  <div class="h-full" :class="{
    'overflow-y-auto custom-scrollbar': !isVideo
  }">
  
    <ScormOverlay
      v-if="activity?.type === ActivityType.SCORM"
      :files="files"
      :content="activity.content"
      :activityId="activity.id"
      @score="setScores"
    />
  
    <ContentOverlay
      v-if="activity?.type === ActivityType.Content"
      :files="files"
      :content="activity.content"
      :activity="activity.id"
      @update:downloadURL="setDownloadURL"
      @update:bookmark="setBookmark"
    />

    <MosaicOverlay
      v-if="activity?.type === ActivityType.Mosaic"
      :files="files"
      :content="activity.content"
      :collection="mediaCollection"
      :activity="activity.id"
      @close="$router.push({name:'3DPage'})"
    />

    <MapOverlay
      v-if="activity?.type === ActivityType.Map"
      :activity="activity.id"
    />

    <SessionMapOverlay
      v-if="activity?.type === ActivityType.SessionMap"
      :activity="activity.id"
      :content="activity.content"
    />

    <UrlRedirectOverlay
      v-if="activity?.type === ActivityType.UrlRedirect"
      :activity="activity.id"
      :content="activity.content"
      @close="$router.push({name:'3DPage'})"
    />
  
    <Quiz2DOverlay
      v-if="activity?.type === ActivityType.Quizz2D"
      :files="files"
      :content="activity.content"
      :activity="$route.params.id"
      @score="setScores"
      @close="$router.push({name:'3DPage'})"
    />

    <ScreenOverlay
      v-if="activity?.type === ActivityType.Screen"
      :content="activity.content"
    />

    <!--
    <div
      v-if="activity.type !== ActivityType.Content && activity.type !== ActivityType.SCORM && activity.type !== ActivityType.Quizz2D && activity.type !== ActivityType.Mosaic"
    >
      {{activity}}
    </div>
    -->

  </div>
</OverlayPage>
</template>

<style scoped>
.d-hidden {
  visibility: hidden;
}
</style>
<style>
.activity-overlay .file-preview-wrapper img.h-full{
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}
</style>
