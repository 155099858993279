<script>
import Logo from '@/assets/imgs/session-map-logo.svg'
import SendToTeamsPopup from '@/components/sendToTeamsPopup'
export default {
  name: 'SessionMapActivity',
  props: ['content', 'files', 'activity'],
  components:{
    Logo,
    SendToTeamsPopup
  },
  data(){
    return{
      teamsLinkPopup:false,
      teamsLink:"",
    }
  },
  methods:{
    joinTeams(link){
      this.teamsLink = link;
      this.teamsLinkPopup = true;
    },
    closeSendToTeamsPopup(){
      this.teamsLink = "";
      this.teamsLinkPopup = false;
    }
  },
  created() {
    //console.log('!!!', this.content, this.activity)
    //this.$store.commit('ui/pausePlayer')
  },
  beforeUnmount() {
    //this.$store.commit('ui/unpausePlayer')
  },
}
</script>

<template>
<div class="h-full">
  <div class="position-relative h-full overflow-y-auto custom-scollbar">
    <div class="d-flex session-map-holder align-items-center bg-fb-navy-3 pt-12">
      <div class="session-map-container w-100 text-center">
        <div class="mb-8">
          <Logo class="countdown-page-logo mb-4"/>
          <h2 class="text-admin-grey-6">{{ $t('sessionMap.title') }}</h2>
        </div>
        <v-container fluid>
          <v-row>
            <v-col cols="6">
              <span class="d-inline-block bg-white py-2 px-4 mb-6 day-label">{{ $t('sessionMap.dayOne.title') }}</span>
              <v-container fluid>
                <v-row>
                  <v-col cols="6">
                    <div class="session-box d-flex flex-column align-items-center bg-admin-grey-6 pa-4">
                      <span class="mb-3">{{ $t('sessionMap.dayOne.boxOne.title') }}</span>
                      <div class="d-flex w-100 mt-auto">
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[0])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[0]">{{ $t('sessionMap.dayOne.boxOne.buttonOne') }}</button>
                        </div>
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[1])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[1]">{{ $t('sessionMap.dayOne.boxOne.buttonTwo') }}</button>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="session-box d-flex flex-column align-items-center bg-admin-grey-6 pa-4">
                      <span class="mb-3">{{ $t('sessionMap.dayOne.boxTwo.title') }}</span>
                      <div class="d-flex w-100 mt-auto">
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[3])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[3]">{{ $t('sessionMap.dayOne.boxTwo.buttonOne') }}</button>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="session-box d-flex flex-column align-items-center bg-admin-grey-6 pa-4">
                      <span class="mb-3">{{ $t('sessionMap.dayOne.boxThree.title') }}</span>
                      <div class="d-flex w-100 mt-auto">
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[2])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[2]">{{ $t('sessionMap.dayOne.boxThree.buttonOne') }}</button>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="6">
              <span class="d-inline-block bg-white py-2 px-4 mb-6 day-label">{{ $t('sessionMap.dayTwo.title') }}</span>
              <v-container fluid>
                <v-row>
                  <v-col cols="6">
                    <div class="session-box d-flex flex-column align-items-center bg-admin-grey-6 pa-4">
                      <span class="mb-3">{{ $t('sessionMap.dayTwo.boxOne.title') }}</span>
                      <div class="d-flex w-100 mt-auto">
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[4])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[4]">{{ $t('sessionMap.dayTwo.boxOne.buttonOne') }}</button>
                        </div>
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[5])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[5]">{{ $t('sessionMap.dayTwo.boxOne.buttonTwo') }}</button>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="session-box bg-admin-grey-6 pa-4 d-flex align-items-center">
                      <div class="d-flex w-100">
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[6])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[6]">{{ $t('sessionMap.dayTwo.boxTwo.buttonOne') }}</button>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="session-box d-flex flex-column align-items-center bg-admin-grey-6 pa-4">
                      <span class="mb-3">{{ $t('sessionMap.dayTwo.boxThree.title') }}</span>
                      <div class="d-flex w-100 flex-wrap three-buttons mt-auto">
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[7])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[7]">{{ $t('sessionMap.dayTwo.boxThree.buttonOne') }}</button>
                        </div>
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[8])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[8]">{{ $t('sessionMap.dayTwo.boxThree.buttonTwo') }}</button>
                        </div>
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[9])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[9]">{{ $t('sessionMap.dayTwo.boxThree.buttonThree') }}</button>
                        </div>
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[10])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[10]">{{ $t('sessionMap.dayTwo.boxThree.buttonFour') }}</button>
                        </div>
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[11])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[11]">{{ $t('sessionMap.dayTwo.boxThree.buttonFive') }}</button>
                        </div>
                        <div class="pa-2 flex-grow-1">
                          <button @click="joinTeams(content.links[12])" class="d-block bg-fb-navy-3 pa-2" :href="content.links[12]">{{ $t('sessionMap.dayTwo.boxThree.buttonSix') }}</button>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
  <!-- send to teams popup -->
  <SendToTeamsPopup
  :link="teamsLink" 
  v-if="teamsLinkPopup" 
  :isVisible="teamsLinkPopup" 
  @close="closeSendToTeamsPopup" />
</div>
</template>

<style lang="scss" scoped>
.session-map-holder{
  min-height:100%;
}
.day-label{
  min-width:150px;
  font-weight: bold;
  font-size: 20px;
  color:rgb(var(--v-theme-fb-navy-3))
}
.session-box{
  outline:1px solid #fff;
  outline-offset: 6px;
  height: 100%;
  min-height: 180px;
  span{
    font-size: 20px;
    font-weight:bold;
    color:rgb(var(--v-theme-fb-navy-3))
  }
  button{
    font-size: 14px;
    width:100%;
    transition: .5s;
    &:hover{
      background-color: rgb(var(--v-theme-fb-navy));
    }
  }
}
.three-buttons > div{
  width:33.33%;
}
</style>

