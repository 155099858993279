<script>
import waitFor from '@/utils/waitFor'
import { useGtag } from "vue-gtag-next";

const SCORM_URL = process.env.VUE_APP_SCORM_URL || ''

export default {
  name: 'ScormActivity',
  props: ['content','activityId'],
  emits: ['score'],
  data() { return {
    url: `/scormplayer/index.html?user=${this.$store.state.user.data.email}&scorm=${SCORM_URL}/${this.content.files[this.$store.state.user.data.locale]}`,
    score: {
      activity: this.activityId,
      gameStart: new Date(),
      gameEnd: null,
      runtime: 0,
      score: 0
    },
    initialized: false,
    open: true,
    lessonStatus: '',
    error: ''
  }},
  async created() {
    window.addEventListener('message', this.parseMessage, false)
    await waitFor(0)
    this.$refs.scorm.contentWindow.beforeunload = () => {
      console.log('+++UNLOAD')
    }
    this.$refs.scorm.contentWindow.addEventListener("beforeunload", function (event) {
      //your code goes here on location change
      console.log(' ** B4 UNLOAD!!', event)
    })

    const { event } = useGtag();
    const track = () => {
      event("scorm_start", {
        event_category: "scorm_start",
        scormId: this.activityId,
        userUid: this.$store.state.user.data.uid,
        userRole: this.$store.state.user.data.role,
        userEmail: this.$store.state.user.data.email,
        userDisplayName: this.$store.state.user.data.displayName,
      });
    };
    track();

  },
  beforeUnmount() {
    window.removeEventListener('message', this.parseMessage)
  },
  methods: {
    parseMessage(msg) {
      if (!msg?.data?.message?.startsWith('scorm:')) return
      // console.log('!! MSG !!', msg.data)
      if (msg.data.message === 'scorm:initialize')
        this.scormInitialize(...msg.data.value)
      if (msg.data.message === 'scorm:commit')
        this.scormCommit(...msg.data.value)
      if (msg.data.message === 'scorm:finish')
        this.scormFinish(...msg.data.value)
      if (msg.data.message === 'scorm:getDiagnostic')
        this.scormGetDiagnostic(...msg.data.value)
      if (msg.data.message === 'scorm:getErrorString')
        this.scormGetErrorString(...msg.data.value)
      if (msg.data.message === 'scorm:getLastError')
        this.scormGetLastError(...msg.data.value)
      if (msg.data.message === 'scorm:getValue')
        this.scormGetValue(...msg.data.value)
      if (msg.data.message === 'scorm:setValue')
        this.scormSetValue(...msg.data.value)
      if (msg.data.message === 'scorm:close')
        this.scormClose()
    },
    scormInitialize(err, errStr) {
      if (err) {
        this.open = false
        this.error = errStr
      }
    },
    scormCommit(...val) {
      console.log('!!! commit', val)
    },
    scormFinish() {
      console.log('!!! finish')
      this.open = false
      this.scormClose()
    },
    scormGetDiagnostic(...val) {
      console.log('!!! get diagnostic', val)
    },
    scormGetErrorString(...val) {
      console.log('!!! get error string', val)
    },
    scormGetLastError(...val) {
      console.log('!!! get last error', val)
    },
    scormGetValue(/*code, val*/) {
      //console.log(`!!! G value <${code}> - [${val}]`)
    },
    async scormSetValue(code, val) {
      //console.log(`!!! S value <${code}> - [${val}]`)
      if (code === 'cmi.core.score.raw') {
        this.score.score = parseInt(val) || 0
        this.$emit('score', this.score.score)

        const { event } = useGtag();
        const track = () => {
          event("scorm_end", {
            event_category: "scorm_end",
            scormId: this.activityId,
            sscore: this.score.score,
            userUid: this.$store.state.user.data.uid,
            userRole: this.$store.state.user.data.role,
            userEmail: this.$store.state.user.data.email,
            userDisplayName: this.$store.state.user.data.displayName,
          });
        };
        track();


      }
      if (code === 'cmi.core.lesson_status') {
        this.lessonStatus = val
        if (val === 'completed') {
          const now = new Date()
          this.score.runtime = now.getTime() - this.score.gameStart.getTime()
          this.score.gameEnd = now
          this.scormClose()
        }
      }
    },
    async scormClose() {
      await this.$store.dispatch('user/submitActivityScore', this.score)
      await waitFor(5000)
      this.$router.push({ name: '3DPage' })
      //console.log('!!! SCORM CLOSE')
    }
  }
}
</script>

<template>
<div class="h-full">
  <iframe v-if="open" ref="scorm" class="scorm-frame" :src="url"/>
  <div class="p-center" v-else>
    <div v-if="error !== ''">
      {{error}}
    </div>
    <div class="scorm-result" v-else>
      {{$t('activity.scorm.resultMessage',{points: score})}}
    </div>
  </div>
</div>
</template>

<style scoped>
iframe.scorm-frame {
  border: 0;
  width: 100%;
  height: 100%;
}
.p-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translatX(-50%) translateY(-50%);
}
</style>
