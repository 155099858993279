<script>
import waitFor from '@/utils/waitFor'

export default {
  name: 'UrlRedirectActivity',
  props: ['content', 'activity'],
  emits: ['close'],
  async created() {
    await waitFor(0)
    const { url, blank } = this.content
    if (!url ||!url.length)
      return
    if (blank) {
      this.$refs.link.click()
      //console.log(this.$refs.link)
      this.$emit('close')
    } else {
      if (this.isLocal) {
        // console.log('router push', this.localUrl)
        this.$router.push(this.localUrl)
      } else {
        window.location.href = url
      }
    }
    //console.log('!!! and close', this.content, this.activity)
    //this.$store.commit('ui/pausePlayer')
  },
  beforeUnmount() {
    //this.$store.commit('ui/unpausePlayer')
  },
  computed: {
    isLocal() {
      if (this.content.url.startsWith('/'))
        return true
      const url = new URL(this.content.url)
      return url.host === window.location.host
    },
    localUrl() {
      if (this.content.url.startsWith('/'))
        return this.content.url
      if (this.content.url.startsWith('http')) {
        const url = new URL(this.content.url)
        return url.pathname + url.search
      }
      return this.content.url
    }
  }
}
</script>

<template>
<div class="h-full">
  <a ref="link" v-if="!isLocal" :target="{'_blank': content.blank}" :href="content.url">{{content.url}}</a>
  <router-link v-else :to="localUrl">{{content.url}}</router-link>
</div>
</template>
