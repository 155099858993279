<script>
import { useGtag } from "vue-gtag-next";
import * as Sentry from "@sentry/vue";
export default {
  name: 'Quizz2DQuestion',
  props: {
    question: {
      type: Object,
      required: true
    },
    files: {
      type: Object,
      required: true
    },
    stepi: {
      type: Number
    },
    quizid: {
      type: String
    }
  },
  emits: ['submitStep','closeStep'],
  data: () => ({
    result: 0,
    selected: {},
    timeLeft: 0,
    timer: null,
    showResults: false
  }),
  created() {
    this.initQuestion()
  },
  computed: {
    canSelect() {
      if (!this.question.maxAnswers)
        return true
      const selected = Object.values(this.selected).reduce((acc,v) => v ? acc + 1 : acc, 0)
      return selected < this.question.maxAnswers
    },
    canSubmit() {
      if (!this.question.maxAnswers)
        return true
      const selected = Object.values(this.selected).reduce((acc,v) => v ? acc + 1 : acc, 0)
      return selected > 0 && this.hasTime
    },
    hasTimer() {
      return this.question.timer > 0
    },
    hasTime() {
      return this.hasTimer ? this.timeLeft > 0 : true
    }
  },
  methods: {
    initQuestion() {
      if (this.timer) {
        try {
          clearInterval(this.timer)
        } catch (e) {
          Sentry.captureException(e)
          console.log('timer?',e)
        }
      }
      this.timer = null
      this.selected = {}
      this.timeLeft = 0
      this.showResults = false
      this.results = 0
      if (this.hasTimer) {
        this.timeLeft = this.question.timer
        this.timer = setInterval(() => {
          this.timeLeft--
          if (this.timeLeft === 0) {
            clearInterval(this.timer)
            this.timer = null
            this.submitStep()
          }
        }, 1000)
      }

    },
    imageExists(ans) {
      const img = this.$l(ans.image)
      if (!img || !this.files[img])
        return false
      return true
    },
    toggleAnswer(ansi) {
      this.selected[ansi] = !this.selected[ansi]
    },
    submitStep() {
      const score = this.question.answers
        .map((ans,ansi) => this.selected[ansi] ? ans.points : 0)
        .reduce((acc,s) => acc + s, 0)
      // console.log('submit step', score)
      this.$emit('submitStep', score)
      this.showResults = true
      this.result = score
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }

      const { event } = useGtag();
      const track = () => {
        event("quiz_step", {
          event_category: "quiz_step",
          score: score,
          step: this.stepi,
          quizId: this.quizid,
          userUid: this.$store.state.user.data.uid,
          userRole: this.$store.state.user.data.role,
          userEmail: this.$store.state.user.data.email,
          userDisplayName: this.$store.state.user.data.displayName,
        });
      };
      track();



    },
    closeStep() {
      // console.log('close step', this.result)

      this.$emit('closeStep', this.result)
    }
  },
  watch: {
    question(next) {
      console.log('Q NEXT', next)
      this.initQuestion()
    }
  }
}
</script>

<template>
<div>
  <div v-if="hasTimer && !showResults" id="quiz-timer" class="font-size-22 mt-6">
    {{timeLeft}}
  </div>
  <h2 class="mb-8 mt-6" :innerHTML="$l(question.prompt)"/>
  <div class="world-quiz-answer-row d-flex justify-center">
    <div
      v-for="ans, ansi of question.answers"
      :key="ansi"
      class="world-quiz-answer"
    >
      <div class="h-full d-flex flex-column justify-content-between">
        <div class="answer-image flex-grow-1" v-if="imageExists(ans)">
          <v-img
            class="answer-image"
            fluid
            minHeight="130"
            minWidth="130"
            :lazy-src="files[$l(ans.image)].details.url"
            :src="files[$l(ans.image)].details.url"
          />
        </div>
        <p class="text-admin-grey-4" :innerHTML="$l(ans.text) || ''"/>
        <div class="text-center mt-10">
          <v-btn
            v-if="selected[ansi] && !showResults"
            :disabled="!hasTime"
            @click="toggleAnswer(ansi)"
            color="fb-navy"
            icon="mdi-check"
          />
          <v-btn
            v-if="!selected[ansi] && !showResults"
            :disabled="!hasTime || !canSelect"
            @click="toggleAnswer(ansi)"
            color="admin-grey-5"
            icon
          />
          <v-btn
            v-if="showResults && ans.points > 0"
            color="success"
            icon="mdi-check"
          />
          <v-btn
            v-if="showResults && ans.points <= 0"
            class="bg-admin-danger"
            icon="mdi-close"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="mt-10">
    <v-btn
      v-if="!showResults"
      color="fb-navy"
      :disabled="!canSubmit"
      class="pa-5"
      @click="submitStep"
      rounded="pill"
    >
      {{$t('buttons.submit')}}
    </v-btn>
    <v-btn
      v-else
      color="fb-navy"
      class="pa-5"
      @click="closeStep"
      rounded="pill"
    >
      {{$t('buttons.next')}}
    </v-btn>
  </div>
<!--
{{question}}
-->
</div>
</template>

<style lang="scss" scoped>
  .world-quiz-answer-row{
    gap: 20px;
  }
  .world-quiz-answer{
    flex-grow:1;
    width:25%;
    .v-btn--disabled.v-btn--variant-contained{
      background-color:rgb(var(--v-theme-admin-grey-5));
      opacity: .5;
    }
  }
</style>

