<script>
export default {
  name: 'TxtPreview',
  props: {
    src: {
      type: String,
      default: null,
      required: true
    }
  },
  mounted(){
    console.log(this.src);
  }
}
</script>

<template>
  <div class="txt-wrappper">
    <iframe :src="src">
      Loading...
    </iframe>
  </div>
</template>

<style lang="scss" scoped>
.txt-wrappper{
  width:100%;
  height:100%;
  iframe{
    background-color: #fff;
    width:100%;
    height: 400px;
  }
}
</style>