<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
import { ActivityType, Scene } from '@/lib/enums'
import genICS from '@/utils/genICS'


export default {
  name: "CalendarEventPreview",
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        content: {
          break:false,
          description: {},
          locale: null,
          name: {},
          primary: null,
          scene:null,
          speakers: [],
          teamsUrl:{},
          video: null
        },
        end:{},
        start:{},
        id:null,
        type: ActivityType.Presentation
      })
    },
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    tz: {
      type: Object,
      required: true
    }
  },
  emits:['close','joinTeams'],
  data(){
    return{
      activity: this.modelValue,
      ics: ''
    }
  },
  async created() {
    this.ics = await this.gen()
  },
  methods:{
    timeStampToTime(time){
      let timeObject = dayjs.unix(time).tz(this.tz.tz).format('hh:mm a');
      return timeObject;
    },
    eventDate(time){
      let eventDate = dayjs.unix(time).tz(this.tz.tz).format('MM/DD');
      return eventDate;
    },
    userName(uid) {
      return this.$store.getters['collections/users/byID'](uid).displayName
    },
    async gen() {
      const a = this.activity
      // console.log(a)
      const ics = await genICS({
        title: this.$l(a.content.name),
        description: this.$l(a.content.description),
        start: a.start.seconds,
        end: a.end.seconds,
        scene: Scene[a.content.scene].toLowerCase()
      })
      // console.log('gen result', ics)
      return ics
    },
    joinTeams(){
      this.$emit('joinTeams', this.$l(this.activity.content.teamsUrl));
    }
  },
  computed: {
    ahref() {
      if (!this.ics) return ''
      const base = btoa(this.ics)
      return `data:text/calendar;base64,${base}`
    }
  }
}
</script>

<template>
  <div class="calender-event-popup" v-if="isVisible">
    <div class="calender-popup-dialog rounded admin-shadow pa-6 bg-white mx-auto">
      <div class="calender-popup-dialog-heading mx-n6 mt-n6 py-4 px-6 mb-6 d-flex justify-space-between align-items-center">
        <span class="text-bold font-size-18">
          {{ $l(activity.content.name) }} | {{ eventDate(activity.start.seconds) }}
          <a class="download-a add-to-calendar-button" :href="ahref" target="_blank" :download="`${$l(activity.content.name)}.ics`">
            <svg width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <rect x="4" y="5" width="16" height="16" rx="2" />
              <line x1="16" y1="3" x2="16" y2="7" />
              <line x1="8" y1="3" x2="8" y2="7" />
              <line x1="4" y1="11" x2="20" y2="11" />
              <line x1="10" y1="16" x2="14" y2="16" />
              <line x1="12" y1="14" x2="12" y2="18" />
            </svg>
            <!-- <v-icon>mdi-download</v-icon> -->
            <span>{{ $t('hoverLabels.addTocalendar') }}</span>
          </a>
        </span>
        <v-btn
          flat
          elevation="0"
          icon="mdi-close"
          size="small"
          class="mr-n3"
          :disabled="$store.state.ui.busy"
          @click="$emit('close')"
        />
      </div>
      <div class="mb-6">
        <div class="mb-2">
          {{ $t('admin.schedulingPage.createCalenderEvent.fields.description') }}
        </div>
        <div v-html="$l(activity.content.description)"></div>
      </div>
      <div class="mb-6">
        <div class="mb-2">
          {{ $t('admin.schedulingPage.createCalenderEvent.fields.starttime') }}
        </div>
        <div>
          {{ timeStampToTime(activity.start.seconds) }}
        </div>
      </div>
      <div class="mb-6">
        <div class="mb-2">
          {{ $t('admin.schedulingPage.createCalenderEvent.fields.endtime') }}
        </div>
        <div>
          {{ timeStampToTime(activity.end.seconds) }}
        </div>
      </div>
      <div class="mb-6" v-if="!activity.content.break">
        <div class="mb-2">
          {{ $t('admin.schedulingPage.createCalenderEvent.fields.speakers') }}
        </div>
        <div>
          {{ activity.content.speakers }}
        </div>
      </div>
      <div v-if="activity.content.break === false">
        <v-btn
          v-if="$l(activity.content.teamsUrl).length"
          color="primary"
          @click="joinTeams"
        >
          {{$t('buttons.joinTeams')}}
        </v-btn>
      </div>
      <!--
      <a :href="$l(activity.content.teamsUrl)" target="_black" rel="noopener noreferrer">{{ $t('admin.schedulingPage.createCalenderEvent.fields.teamsUrl') }}</a>
      -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calender-event-popup{
  position: fixed;
  z-index: 1000;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(var(--v-theme-black),.7);
  overflow-y: auto;
  padding: 50px 15px;
}
.calender-popup-dialog{
  max-width:800px;
  &-heading{
    border-bottom:1px solid rgb(var(--v-theme-fb-grey));
  }
}
.download-a {
  text-decoration: none;
  color: #000;
}
.add-to-calendar-button{
  position: relative;
  svg{
    margin-bottom: -8px;
    margin-left: 5px;
  }
  span{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 20px);
    background-color: rgb(var(--v-theme-black));
    color: rgb(var(--v-theme-white));
    padding: 4px 10px;
    font-size: 14px;
    border-radius: 6px;
    white-space: nowrap;
    opacity: 0;
    transition: all .5s;
    pointer-events: none;
    box-shadow: 0 0 4px 0 rgba(var(--v-theme-black),.5);
  }
  &:hover{
    span{
      opacity: 1;
      top: calc(100% + 10px);
    }
  }
}
</style>
