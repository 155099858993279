<script>
import OverlayPage from '@/components/OverlayPage.vue'
export default {
  name: "VideoChatWidget",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    }
  },
  emits:['video-chat-close'],
  components:{
    OverlayPage
  }
};
</script>

<template>
<OverlayPage
  class="video-chat-overlay"
  @close="$emit('video-chat-close')"
  :fullscreen="false"
>
  <div class="h-full position-relative">
    <iframe class="video-chat-frame" src="https://spatial.chat/s/facebookhackersacademy" frameborder="0" seamless="seamless" height="700px" width="100%" scrolling="yes" allow="camera *;microphone *;autoplay"></iframe>
  </div>
</OverlayPage>
</template>

<style lang="scss">
.video-chat-overlay{
  #overlay-controls{
    display: none!important;
  }
} 
.video-chat-frame{
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  border:0 none;
}
</style>
