<script>
import { MIME } from '@/lib/enums'

import MediaPlayer from "@/components/MediaPlayer";
import CsvPreview from "@/components/CsvPreview";
import TxtPreview from "@/components/TxtPreview";
import PdfPreview from "@/components/PdfPreview";

export default {
  name: 'FilePreview',
  components:{
    MediaPlayer,
    PdfPreview,
    CsvPreview,
    TxtPreview,
  },
  props: {
    file: {
      required: true
    },
    mode: {
      type: String,
      default: 'full'
    },
    displayName: {
      type: String,
      default: undefined
    }
  },
  setup: () => ({ MIME })
}
</script>

<template>
  <div
    v-if="!!file"
    class="file-preview-wrapper text-center"
    :class="{'h-full': mode === 'fit'}"
  >
    <img
      v-if="file.MIME === 3"
      :class="{'img-fluid': mode !== 'fit', 'h-full': mode === 'fit'}"
      :src="file.details.url.split('?').shift()"
      :alt="file.details.filename"
    />
    <PdfPreview v-if="file.MIME === 2" :src="file.details.url.split('?').shift()" />
    <CsvPreview v-if="file.MIME === 0" :src="file.details.url.split('?').shift()" />
    <TxtPreview v-if="file.MIME === 1" :src="file.details.url.split('?').shift()" />
    <MediaPlayer
      v-if="file.MIME === 4 || file.MIME === 5"
      :file="file"
      :type="file.MIME"
      :mode="mode"
      :displayName="displayName"
    />
  </div>
</template>

<style scoped lang="scss">
  .file-preview-wrapper{
    width:100%;
    margin:0 auto;
    position:relative;
    .img-fluid{
      margin: 0 auto;
    }
  }
  .img-fluid{
    width:100%;
    max-width: 100%;
    display: block;
    height: auto;
  }
</style>
