<script>
import FilePreview from '@/components/FilePreview'
import IconFullScreenClose from '@/assets/imgs/icons/icon-full-screen-close.svg'

export default {
  name: 'FullScreenFilePreview',
  components: {
    FilePreview,
    IconFullScreenClose
  },
  emits: [
    'closeFullScreen'
  ],
  props: {
    file: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div v-if="file" class="full-screen-file-preview overflow-y-auto custom-scrollbar">
    <v-btn
      class="full-screen-exit-button text-black pa-2"
      flat
      elevation="0"
      @click="$emit('closeFullScreen')"
      >
      <IconFullScreenClose/>
    </v-btn>
    <FilePreview :file="file"/>
  </div>
</template>

<style lang="scss" scoped>
.full-screen-file-preview{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(var(--v-theme-black), .95);
  z-index:10000;
  padding: 50px 15px;
  .file-preview-wrapper{
    max-width: 700px;
  }
}
.full-screen-exit-button{
  position: fixed;
  top:10px;
  right:10px;
  z-index:10;
  min-width: auto;
}
</style>
