import  * as ics from 'ics'
import dayjs from 'dayjs'
import * as Sentry from "@sentry/vue";

const genICS = info => new Promise((resolve, reject) => {
  try {
    const start = dayjs.unix(info.start)
    const end = dayjs.unix(info.end)
    const opts = {
      title: info.title,
      description: info.description,
      start: [start.year(), start.month(), start.day(), start.hour(), start.minute()],
      startInputType: 'utc',
      url: `${window.location.origin}/3d/${info.scene}`,
      duration: { minutes: end.diff(start,'m') }
    }
    ics.createEvent(opts, (err, res) => {
      if (err) {
        Sentry.captureException(err)
        reject(err)
      } else {
        resolve(res)
      }
    })
  } catch (error) {
    Sentry.captureException(error)
    console.log('ERROR!')
    console.error(error)
    resolve(null)
  }
})

export default genICS
