<script>
import IconCourtyard from '@/assets/imgs/icons/navigation/courtyard.svg'
import IconOffice from '@/assets/imgs/icons/navigation/office.svg'
import IconRooftop from '@/assets/imgs/icons/navigation/rooftop.svg'
import IconStore from '@/assets/imgs/icons/navigation/store.svg'
import IconLoft from '@/assets/imgs/icons/navigation/loft.svg'
import IconApartment from '@/assets/imgs/icons/navigation/apartment.svg'
import IconWarehouse from '@/assets/imgs/icons/navigation/warehouse.svg'

import { Scene } from '@/lib/enums'

import waitFor from '@/utils/waitFor'

export default {
  name: 'NavigationBar',

  components: {
    IconCourtyard,
    IconOffice,
    IconRooftop,
    IconLoft,
    IconApartment,
    IconWarehouse,
    IconStore
  },
  methods: {
    uiButton(scene, navigate) {
      const quizId = this.$store.state.scene.data.quiz
      const userScores = this.$store.getters['user/score/list']

      // console.log('check for last quizz here!', quizId, this.$store.state.scene.name)

      let ok = false
      if (!quizId || quizId === '') {
        ok = true
      } else {
        const quiz = this.$store.getters['collections/activities/byID'](quizId)
        if (!quiz) {
          ok = true
        } else {
          const max = quiz.content.scores.repeats
          const played = userScores.filter(score => score.activity === quizId).length
          ok = played >= max
        }
      }

      if (ok) {
        navigate()
      } else {
        this.$store.commit('scene/setRedirectOnActionClose',{
          name: '3DPage',
          params: { scene }
        })
        this.$router.push({
          name: 'ActivityOverlay',
          params: { id: quizId }
        })
      }
    },
  },
  computed: {
    navigationEnabled() {
      // console.log('$rt', this.$route.name)
      return this.$route.name === '3DPage'
    }
  },
  async created(){
    await waitFor(0);
    let uiButtons =  document.getElementsByClassName("overlay-ui-icon-button");
    for (let i = 0; i < uiButtons.length; i++) {
      uiButtons[i].addEventListener('mousedown', function(evt) {
        evt.stopPropagation();
      });
    } 
  },
  setup: () => ({ Scene })
}
</script>

<template>
<div class="wrapper py-2">
  <div class="nav-links-holder">
    <!-- Campus -->
    <div class="my-2">
      <router-link
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
        :to="{name: '3DPage', params: { scene : 'campus' }}"
      >
        <a v-if="navigationEnabled" :href="href" @click.stop.prevent="uiButton('campus', navigate)"
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconCourtyard/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Campus}`)}}</span>
        </a>
        <span
          v-else
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconCourtyard/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Campus}`)}}</span>
        </span>
      </router-link>
    </div>
    <!-- Office -->
    <div class="my-2">
      <router-link
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
        :to="{name: '3DPage', params: { scene : 'office' }}"
      >
        <a v-if="navigationEnabled" :href="href" @click.stop.prevent="uiButton('office', navigate)"
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconOffice/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Office}`)}}</span>
        </a>
        <span
          v-else
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconOffice/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Office}`)}}</span>
        </span>
      </router-link>
    </div>
    <!-- Store -->
    <div class="my-2">
      <router-link
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
        :to="{name: '3DPage', params: { scene : 'store' }}"
      >
        <a v-if="navigationEnabled" :href="href" @click.stop.prevent="uiButton('store', navigate)"
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconStore/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Store}`)}}</span>
        </a>
        <span
          v-else
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconStore/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Store}`)}}</span>
        </span>
      </router-link>
    </div>
    <!-- Pool -->
    <div class="my-2">
      <router-link
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
        :to="{name: '3DPage', params: { scene : 'pool' }}"
      >
        <a v-if="navigationEnabled" :href="href" @click.stop.prevent="uiButton('pool', navigate)"
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconRooftop/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Pool}`)}}</span>
        </a>
        <span
          v-else
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconRooftop/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Pool}`)}}</span>
        </span>
      </router-link>
    </div>
    <!-- Loft -->
    <div class="my-2">
      <router-link
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
        :to="{name: '3DPage', params: { scene : 'loft' }}"
      >
        <a v-if="navigationEnabled" :href="href" @click.stop.prevent="uiButton('loft', navigate)"
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconLoft/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Loft}`)}}</span>
        </a>
        <span
          v-else
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconLoft/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Loft}`)}}</span>
        </span>
      </router-link>
    </div>
    <!-- Apartment -->
    <div class="my-2">
      <router-link
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
        :to="{name: '3DPage', params: { scene : 'apartment' }}"
      >
        <a v-if="navigationEnabled" :href="href" @click.stop.prevent="uiButton('apartment', navigate)"
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconApartment/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Apartment}`)}}</span>
        </a>
        <span
          v-else
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconApartment/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Apartment}`)}}</span>
        </span>
      </router-link>
    </div>
    <!-- Warehouse -->
    <div class="my-2">
      <router-link
        custom
        v-slot="{ href, navigate, isActive, isExactActive }"
        :to="{name: '3DPage', params: { scene : 'warehouse' }}"
      >
        <a v-if="navigationEnabled" :href="href" @click.stop.prevent="uiButton('warehouse', navigate)"
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconWarehouse/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Warehouse}`)}}</span>
        </a>
        <span
          v-else
          class="overlay-ui-icon-button d-flex justify-center align-items-center"
         :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"
        >
          <IconWarehouse/>
          <span class="overlay-ui-icon-title">{{$t(`scenes.${Scene.Warehouse}`)}}</span>
        </span>
      </router-link>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}
.overlay-ui-icon-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  z-index:100;
  svg{
    width:30px;  
  }
}
a.overlay-ui-icon-button{
  svg{
    top: 0;
  }
  &.router-link-active{
    background-color: rgb(var(--v-theme-primary));
    border-color: rgb(var(--v-theme-primary));
  }
}
.nav-links-holder{
  max-height:100%;
  &::-webkit-scrollbar{
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
.overlay-ui-icon-title{
  color: rgb(var(--v-theme-black));
  background-color: rgb(var(--v-theme-white));
  position: absolute;
  left: 130%;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px 10px;
  border-radius: 4px;
  z-index: 2;
  opacity: 0;
  transition: all .5s;
}
a.overlay-ui-icon-button:hover{
  .overlay-ui-icon-title{
    left: 115%;
    opacity: 1;
  }  
}
</style>
