<script>


export default {
  name: "sendToTeamsPopup",
  props: {
    link:{
      type: String,
      default: ""
    },
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    tz: {
      type: Object,
      required: true
    }
  },
  emits:['close']
}
</script>

<template>
  <div class="calender-event-popup" v-if="isVisible">
    <div class="calender-popup-dialog rounded admin-shadow pa-6 bg-white mx-auto">
      <div class="calender-popup-dialog-heading mx-n6 mt-n6 py-4 px-6 mb-6 d-flex justify-end align-items-center">
        <v-btn
          flat
          elevation="0"
          icon="mdi-close"
          size="small"
          class="mr-n3"
          :disabled="$store.state.ui.busy"
          @click="$emit('close')"
        />
      </div>
      <div class="text-center">
        <p class="mb-4">{{$t('calendar.sendToTeamsNote')}}</p>
        <a @click="$emit('close')" class="v-btn v-btn--elevated v-theme--light bg-primary v-btn--density-default v-btn--size-default v-btn--variant-contained" :href="link" target="_black" rel="noopener noreferrer">{{$t('buttons.joinTeams')}}</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calender-event-popup{
  position: fixed;
  z-index: 1000;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(var(--v-theme-black),.7);
  overflow-y: auto;
  padding: 50px 15px;
}
.calender-popup-dialog{
  max-width:800px;
  &-heading{
    border-bottom:1px solid rgb(var(--v-theme-fb-grey));
  }
}
.download-a {
  text-decoration: none;
  color: #000;
}
.add-to-calendar-button{
  position: relative;
  svg{
    margin-bottom: -8px;
    margin-left: 5px;
  }
  span{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 20px);
    background-color: rgb(var(--v-theme-black));
    color: rgb(var(--v-theme-white));
    padding: 4px 10px;
    font-size: 14px;
    border-radius: 6px;
    white-space: nowrap;
    opacity: 0;
    transition: all .5s;
    pointer-events: none;
    box-shadow: 0 0 4px 0 rgba(var(--v-theme-black),.5);
  }
  &:hover{
    span{
      opacity: 1;
      top: calc(100% + 10px);
    }
  }
}
</style>
