<script>
export default {
  name: 'ScreenActivity',
  props: ['content', 'files', 'activity'],
  created() {
    //console.log('!!!', this.content, this.activity)
    this.$store.commit('ui/pausePlayer')
  },
  beforeUnmount() {
    this.$store.commit('ui/unpausePlayer')
  },
}
</script>

<template>
<div class="h-full">
  <div id="iframe-wrapper" class="position-relative h-full overflow-y-auto custom-scollbar" :innerHTML="content?.video || ''">
  <!--
    <iframe class="meeting-iframe" src="https://video.ibm.com/combined-embed/24150199?videos=0&autoplay=true&showtitle=false&theme=g10&messageVerticalSpacing=default&mainLinkColor=0f62fe&moderatorNameColor=d12771" style="border: 0;" webkitallowfullscreen allowfullscreen frameborder="no" referrerpolicy="no-referrer-when-downgrade"></iframe>
    -->
  </div>
</div>
</template>

<style>
#iframe-wrapper iframe {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
</style>

