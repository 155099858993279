<script>
import Pdf from 'pdfvuer'

export default {
  name: 'PdfPreview',
  components:{
    Pdf
  },
  props: {
    src: {
      type: String,
      default: null,
      required: true
    }
  },
  data(){
    return{
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 'page-width',
      dynamicKey: Math.random(10000)
    }
  },
  created () {
    this.getPdf(this.src);
  },
  computed:{
    
  },
  methods: {
    generateKey(){
      return Math.random(10000);
    },
    getPdf (file) {
      var vm = this;
      vm.pdfdata = Pdf.createLoadingTask(file);
      vm.dynamicKey = Math.random(10000);
      vm.pdfdata.then(pdf => {
        vm.numPages = pdf.numPages;
      });
    },
  },
  watch:{
    src:function(){
      this.getPdf(this.src);
    }
  }
}
</script>

<template>
  <div class="pdf-preview-wrapper">
    <Pdf :src="pdfdata" v-for="i in numPages" :key="generateKey(i)" :id="i" :page="i" />
  </div>
</template>

<style lang="scss" scoped>
@import 'src/assets/_variables.scss';
.pdf-preview-wrapper{
  > div{
    box-shadow: $admin-shadow;
    &:not(:last-child){
      margin-bottom: 15px;
    }
  }
}
</style>