<script>
import Logo from '@/assets/imgs/logo-leaderboard.svg'
import IconClose from '@/assets/imgs/icons/close.svg'

import waitFor from '@/utils/waitFor'

export default {
  name: 'Leaderboard',
  components: {
    Logo,
    IconClose
  },
  data: () => ({
    scores: {}
  }),
  computed: {
    list() {
      const list = Object.values(this.scores || {})
      const grouped = {}
      list.forEach(score => {
        if (!score.uid)
          return
        if (!grouped[score.uid])
          grouped[score.uid]  = {
            score: 0,
            displayName: score.displayName,
            runtime: 0
          }
        const dname = score.displayName
        if (grouped[score.uid].displayName === '' && dname !== '')
          grouped[score.uid].displyName = dname
        grouped[score.uid].score += score.score
        grouped[score.uid].runtime += score.runtime
      })
      // console.log('list scores', list, grouped)
      return Object.values(grouped)
        .sort((a,b) =>
          a.score === b.score
            ? a.runtime === b.runtime
              ? 0
              : a.runtime > b.runtime
                ? 1
                : -1
            : a.score > b.score
              ? -1
              : 1
        )
        .slice(0,10)
    }
  },
  methods: {
    getPos(i) {
      const p = i + 1
      return p === 10 ? `${p}` : `0${p}`
    },
    async updateScores() {
      // const source = this.$store.state.ui.leaderboard
      const source = 'global'
      this.scores = await this.$store.dispatch('collections/getActivityLeaderboard', source, 0)
      //console.log('scores', this.scores)
    },
    close(){
      this.$store.commit('ui/setLeaderboardOpen',!this.$store.state.ui.leaderboardOpen)
    }
  },
  watch: {
    async '$store.state.ui.leaderboard'(next, prev) {
      if (next !== prev)
        this.updateScores()
    }
  },
  async created() {
    this.updateScores()
    await waitFor(0);
    let leaderBoardPage = this.$refs.leaderBoardPage;
    if(leaderBoardPage && typeof leaderBoardPage.addEventListener === 'function'){
      leaderBoardPage.addEventListener('mousedown', function(evt) {
        evt.stopPropagation();
      });
    }
  }
}
</script>

<template>
<div
  id="leader-board"
  class="text-center"
  ref="leaderBoardPage"
>
  <button @click="close" class="leaderboard-close-button" ref="close">
    <IconClose/>
  </button>
  <div class="wrapper h-full">
    <Logo/>
    <hr class="mt-4"/>
    <div v-if="!list.length" class="mt-6">
      {{$t('activity.leaderBoardEmpty')}}
    </div>
    <ul v-else class="table">
      <li class="help-row mt-5">{{$t('activity.leaderBoardHelp')}}</li>
      <li
        v-for="score, i of list"
        :key="i"
        class="d-flex score-row font-size-18 pt-3"
      >
        <div class="position mr-4">{{getPos(i)}}</div>
        <div class="username flex-grow-1 text-left">{{score.displayName}}</div>
        <div class="score">{{score.score}}</div>
      </li>
    </ul>
  </div>
</div>
</template>

<style lang="scss" scoped>
#leader-board {
  position: absolute;
  top: 100px;
  left: 200px;
  right: 200px;
  color: #fff;

  .wrapper {
    background: rgba(var(--v-theme-black), .9);
    padding: 60px;
    border-radius: 6px;
  }

  .score-row {
    list-style: none;
    text-transform: uppercase;
  }
  .help-row {
    list-style: none;
  }
}
.leaderboard-close-button {
    position: absolute;
    top: 13px;
    right: 15px;
    &:hover{
      svg g{
        stroke:#919596;    
      }
    }
  }
</style>
