<script>
// import IconBell from '@/assets/imgs/icons/bell.svg'
import IconCalendar from '@/assets/imgs/icons/calendar.svg'
import IconUserNoAvatar from '@/assets/imgs/icons/user-no-avatar.svg'
import IconSettings from '@/assets/imgs/icons/settings.svg'
import IconSound from '@/assets/imgs/icons/sound.svg'
import IconLeaderboard from '@/assets/imgs/icons/leaderboard.svg'
//import IconMicrosoftTeams from '@/assets/imgs/icons/microsoft-teams.svg'
// import IconChat from '@/assets/imgs/icons/icon-chat.svg'
import IconChat from '@/assets/imgs/icons/icon-chat.svg'
import IconVideoChat from '@/assets/imgs/icons/icon-video-chat.svg'
// import IconW from '@/assets/imgs/icons/workspace.svg'
import UiMusicMixer from '@/components/UiMusicMixer.vue'

import NavigationBar from '@/components/NavigationBar.vue'
import ChatWidget from '@/components/chat/ChatWidget'
import VideoChatWidget from '@/components/chat/VideoChatWidget'
//import NotificationWidget from '@/components/notification/NotificationWidget'

import mediaKeyboard from '@/assets/imgs/media-keyboard.png'
import mediaKey360 from '@/assets/imgs/media-360.png'

import NotificationButton from '@/components/notification/NotificationButton'

import HelpScreen from '@/components/HelpScreens'

import LeaderBoard from '@/components/activity/Leaderboard'

import waitFor from '@/utils/waitFor'

export default {
  name: 'Overlay3D',
  props: {
    active: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    mixerOpen: false,
    chatActivated: false,
    notificationActivated: false,
    helpScreen: localStorage.getItem('helpScreen') || "visible",
    videoChatActivated:false,
    hasNewMessages: false
  }),
  components: {
    LeaderBoard,
    NavigationBar,
    UiMusicMixer,
    //IconBell,
    IconCalendar,
    IconSettings,
    IconSound,
    IconLeaderboard,
    //IconMicrosoftTeams,
    IconChat,
    IconVideoChat,
    // IconW,
    //IconChat,
    //IconW,
    IconUserNoAvatar,
    ChatWidget,
    VideoChatWidget,
    HelpScreen,
    NotificationButton,
    // NotificationWidget
  },
  methods: {
    async uiButton(code) {
      // console.log('ui', code)
      if (code === 'user')
        this.$router.push({name:'Profile'})
      if (code === 'settings')
        this.$router.push({name:'SettingsPage'})
      if (code === 'sound') {
        await waitFor(0)
        this.mixerOpen = true
      }
      if (code === 'chat')
        this.chatActivated = !this.chatActivated
      if (code === 'video-chat')
        this.videoChatActivated = !this.videoChatActivated
      if (code === 'calendar')
        this.$router.push({name:'3DSchedulePage'})
      if (code === 'leaderboard')
        this.$store.commit('ui/setLeaderboardOpen',!this.$store.state.ui.leaderboardOpen)
      if (code === 'bell')
        this.notificationActivated = !this.notificationActivated
    },
    closeHelpScreen(){
      this.helpScreen = 'invisible'
    }
  },
  setup:() => ({
    mediaKeyboard,
    mediaKey360
  }),
  watch: {
    '$store.state.user.dm.hasNew': {
      deep: true,
      handler(next) {
        this.hasNewMessages = Object.values(next).some(a => !!a)
        //console.log('hasNew updated in Overlay3D', next, prev)
      }
    }
  }
}
</script>

<template>
  <div v-if="!$store.state.pc.splash" id="overlay-ui" :class="{active}">
    <div class="help-screen-backdrop" v-if="helpScreen === 'visible'"></div>
    <NavigationBar id="navigation" :class="helpScreen === 'visible' ? 'has-hep-screen' : ''"/>
    <router-view name="overlayUi"/>
    <span class="threed-indicator">
      <img class="media-keyboard" :src="mediaKeyboard" alt="keyboard" />
      <img class="media-360" :src="mediaKey360" alt="360" />
    </span>
    <button @click.capture.prevent="uiButton('calendar')" class="overlay-ui-icon-button" id="icon-calendar">
      <IconCalendar/>
      <span class="icon-tooltip">{{$t('hoverLabels.eventSchedule')}}</span>
    </button>
    <button v-if="$store.state.ui.leaderboard" @click.stop="uiButton('leaderboard')" class="overlay-ui-icon-button" id="icon-leaderboard" :class="{'bg-primary': $store.state.ui.leaderboardOpen}">
      <IconLeaderboard/>
      <span class="icon-tooltip">{{$t('hoverLabels.leaderBoard')}}</span>
    </button>
    <LeaderBoard
      :class="{'d-hidden': !$store.state.ui.leaderboardOpen}"
    />
    <NotificationButton
      id="icon-bell"
    />
    <!--
    <button @click.stop="uiButton('bell')" class="overlay-ui-icon-button" id="icon-bell">
      <IconBell/>
      <span class="icon-tooltip">{{$t('hoverLabels.notification')}}</span>
    </button>
    <NotificationWidget v-if="notificationActivated" :isOpen="notificationActivated"  @widgetClose="notificationActivated = !notificationActivated"/>
    -->
    <button
      @click.stop="uiButton('user')"
      class="overlay-ui-icon-button"
      id="icon-profile"
      :style="{
        backgroundImage: `url(${$store.state.user.data?.avatar})`
      }"
    >
      <IconUserNoAvatar v-if="!$store.state.user.data?.avatar"/>
      <span class="icon-tooltip">{{$t('hoverLabels.profile')}}</span>
    </button>
    <UiMusicMixer id="music-mixer" v-if="mixerOpen" @close="mixerOpen = false"/>
    <button @click.stop="mixerOpen = !mixerOpen" class="overlay-ui-icon-button" id="icon-sound">
      <IconSound/>
      <span class="icon-tooltip">{{$t('hoverLabels.audioSetting')}}</span>
    </button>
    <button @click.stop="uiButton('settings')" class="overlay-ui-icon-button" id="icon-settings">
      <IconSettings/>
      <span class="icon-tooltip">{{$t('hoverLabels.settings')}}</span>
    </button>
    <!--
    <button @click.stop="uiButton('w')" class="overlay-ui-icon-button" id="icon-w">
      <IconW/>
    </button>
    -->
    <!-- <button @click.stop="uiButton('microsoft-teams')" class="overlay-ui-icon-button" id="icon-microsoft-teams">
      <IconMicrosoftTeams/>
    </button> -->
    <button @click.stop="uiButton('video-chat')" class="overlay-ui-icon-button" id="icon-video-chat">
      <IconVideoChat/>
      <span class="icon-tooltip">{{$t('hoverLabels.videoChat')}}</span>
    </button>
    <VideoChatWidget v-if="videoChatActivated" :isOpen="videoChatActivated"  @videoChatClose="videoChatActivated = !videoChatActivated"/>
    <button @click.stop="uiButton('chat')" class="overlay-ui-icon-button" id="icon-chat">
      <IconChat/>
      <span class="icon-tooltip">{{$t('hoverLabels.chat')}}</span>
      <span class="unread-mark" v-if="hasNewMessages"/>
    </button>
    <ChatWidget :class="{ 'd-none': !chatActivated }" :isOpen="chatActivated"  @chatboxClose="chatActivated = !chatActivated"/>
    <!--
    <ChatWidget v-if="chatActivated" :isOpen="chatActivated"  @chatboxClose="chatActivated = !chatActivated"/>
    -->
    <HelpScreen v-if="helpScreen === 'visible'" @hideHelpScreen="closeHelpScreen"/>
  </div>
</template>

<style scoped lang="scss">
.d-hidden {
  visibility: hidden;
}

#overlay-ui {
  --gradient-black: rgba(0,0,0,0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  overflow: hidden;

  &.active {
    background: linear-gradient(90deg, var(--gradient-black), transparent 23%, transparent 70%, var(--gradient-black));
  }
}

#navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  height: calc(100% - 190px);
  display:flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  &.has-hep-screen{
    height: calc(100% - 390px);
  }
}

.threed-indicator{
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  .media-keyboard{
    width:120px;
    filter: brightness(3);
    display: block;
  }
  .media-360{
    position: absolute;
    width:60px;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    filter: brightness(2);
    display: block;
  }
}

.icon-tooltip{
  color: rgb(var(--v-theme-black));
  background-color: rgb(var(--v-theme-white));
  position: absolute;
  padding: 4px 10px;
  border-radius: 4px;
  z-index: 2;
  opacity: 0;
  transition: all 0.5s;
  pointer-events: none;
  white-space: nowrap;
}

#icon-calendar {
  top: 15px;
  left: 25px;
  .icon-tooltip{
    left: 130%;
    top: 50%;
    transform: translateY(-50%);
  }
  &:hover{
    .icon-tooltip{
      left: 115%;
    }
  }
}

#icon-leaderboard {
  top: 15px;
  left: calc(50% - 25px);
  z-index: 1000;

  svg {
    top: 3px;
    width: 30px;
  }
  .icon-tooltip{
    left:130%;
    top:50%;
    transform:translateY(-50%);
  }
  &:hover{
    .icon-tooltip{
      left:115%;
    }
  }
}

#icon-bell {
  top: 15px;
  right: 140px;
  position: absolute;
  /*
  .icon-tooltip{
    right:130%;
    top:50%;
    transform:translateY(-50%);
  }
  &:hover{
    .icon-tooltip{
      right:115%;
    }
  }
  */
}

#icon-profile {
  top: 15px;
  right: 25px;
  background-size: cover;
  background-position: center;

  svg {
    top: -1px !important;
  }
  .icon-tooltip{
    right:0;
    top:130%;
  }
  &:hover{
    .icon-tooltip{
      top:120%;
    }
  }
}

#icon-sound {
  left: 90px;
  bottom: 15px;
  .icon-tooltip{
    left:0;
    bottom:130%;
  }
  &:hover{
    .icon-tooltip{
      bottom:120%;
    }
  }
}

#music-mixer + #icon-sound {
  .icon-tooltip{
    opacity: 0;
  }
}

#icon-settings {
  left: 25px;
  bottom: 15px;
  .icon-tooltip{
    left:130%;
    top:50%;
    transform:translateY(-50%);
  }
  &:hover{
    .icon-tooltip{
      left:115%;
    }
  }
}

// #icon-microsoft-teams {
//   right: 90px;
//   bottom: 15px;
//   background: #fff;
// }
#icon-chat{
  right: 25px;
  bottom: 15px;
  background: #fff;
  .icon-tooltip{
    right:0;
    bottom:130%;
  }
  &:hover{
    .icon-tooltip{
      bottom:115%;
    }
  }
  .unread-mark {
    position: absolute;
    top: 2px;
    right: 2px;
    background: #F00;
    width: 12px;
    height: 12px;
    border: 2px solid #FFF;
    border-radius: 6px;
  }
}

#icon-video-chat{
  right: 90px;
  bottom: 15px;
  background: #fff;
  .icon-tooltip{
    right:130%;
    top:50%;
    transform:translateY(-50%);
  }
  &:hover{
    .icon-tooltip{
      right:115%;
    }
  }
}

#music-mixer {
  position: absolute;
  left: 96px;
  bottom: 70px;
  z-index: 100;
}
/*
#icon-w {
  right: 30px;
  bottom: 30px;
  background: #fff;

  svg {
    top: 3px;
    left: -1px;
  }
}
*/
// help screen backdrop
.help-screen-backdrop{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(var(--v-theme-black), .9);
  z-index: 99;
}

</style>
