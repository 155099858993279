<script>
import OverlayPage from '@/components/OverlayPage.vue'
import waitFor from '@/utils/waitFor'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import toArray from 'dayjs/plugin/toArray'
import timezone from "dayjs/plugin/timezone";
import { ActivityType, Scene } from '@/lib/enums'
//import SceneIcon from '@/components/SceneIcon'
import CalenderEventPreview from '@/components/CalenderEventPreview'
import SendToTeamsPopup from '@/components/sendToTeamsPopup'
import  * as ics from 'ics'

dayjs.extend(toArray);
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: '3dScedulePage',
  components: {
    OverlayPage,
    //SceneIcon,
    CalenderEventPreview,
    SendToTeamsPopup
  },
  beforeUnmount() {
    if (this.$store.state.pc.app)
      this.$store.state.pc.app.fire('ui:open', false)
  },
  data() { 
    return {
      calendarEvents: {},
      hours: 24,
      eventPreviewVisible:false,
      day: dayjs(),
      timezone: localStorage.getItem('ui/calendar/timezone') || 'LOCAL',
      ics: '',
      teamsLink:"",
      teamsLinkPopup:false
    }
  },
  async created(){
    await waitFor(0)
    if (this.$store.state.pc.app)
      this.$store.state.pc.app.fire('ui:open', true);

    const lastDay = localStorage.getItem('ui/calendar/day')
    this.day = lastDay
      ? dayjs(lastDay)
      : dayjs(dayjs().format('YYYY-MM-DD 00:00'))
    //console.log('created day', this.day)
    this.genICS()

  },
  setup: () => ({dayjs}),
  methods: {
    async genICS() {

      const list = (this.presentations || []).map(ev => {
        /* TODO */
        const start = this.timezone === 'LOCAL'
          ? dayjs.unix(ev.start.seconds)
          : dayjs.unix(ev.start.seconds).tz(this.timeZones[this.timezone].tz)
        const end = this.timezone === 'LOCAL'
          ? dayjs.unix(ev.end.seconds)
          : dayjs.unix(ev.end.seconds).tz(this.timeZones[this.timezone].tz)
        /**/
        // const start = dayjs.unix(ev.content.start.seconds)
        // const end = dayjs.unix(ev.content.end.seconds)
        //console.log('?start', start, start.toArray())
        const arr = start.toArray().slice(0,-1)
        arr[1]++
        return {
          title: this.$l(ev.content.name),
          description: this.$l(ev.content.description),
          start: arr,
          duration: { minutes: end.diff(start,'m') },
          url: `${window.location.origin}/3d/${Scene[ev.content.scene].toLowerCase()}`,
        }
      })

      this.ics = ics.createEvents(list).value || null
      //console.log('list', list, this.ics)
    },
    cycleTimeZone() {
      this.timezone = this.timezone === 'LOCAL'
        ? 'PST'
        : this.timezone === 'PST'
          ? 'CET'
          : 'LOCAL'
      localStorage.setItem('ui/calendar/timezone', this.timezone)
      //this.day = dayjs( this.day.format('YYYY-MM-DD 00:00')+ ' ' + this.timeZones[this.timezone].utc)
    },
    goMinusOne() {
      this.day = this.day.add(-1, 'day')
      localStorage.setItem('ui/calendar/day', this.day.format('YYYY-MM-DD 00:00'))
      this.genICS()
    },
    goPlusOne() {
      this.day = this.day.add(1, 'day')
      localStorage.setItem('ui/calendar/day', this.day.format('YYYY-MM-DD 00:00'))
      this.genICS()
    },
    timeStampToTime(time){
      let timeObject = dayjs.unix(time).tz(this.timeZones[this.timezone].tz).format('hh:mm a');
      return timeObject;
    },
    openPreview(value){
      this.eventValues = value;
      this.eventPreviewVisible = true;
    },
    closePreview(){
      this.eventValues = {};
      this.eventPreviewVisible = false;
    },
    closeSendToTeamsPopup(){
      this.teamsLink = "";
      this.teamsLinkPopup = false;
    },
    joinTeams(link){
      this.eventPreviewVisible = false;
      this.teamsLink = link;
      this.teamsLinkPopup = true;
    }
  },
  computed:{
    timeZones: () => ({
      PST: {
        tz: 'America/Los_Angeles',
        utc: 'UTC-7'
      },
      CET: {
        tz: 'Europe/Rome',
        utc: 'UTC+2'
      },
      LOCAL: {
        tz: dayjs.tz.guess(),
        utc: ''
      }
    }),
    jsday() {
      return dayjs( this.day.format('YYYY-MM-DD 00:00')+ ' ' + this.timeZones[this.timezone].utc)
    },
    presentations() {
      return (this.$store.getters['collections/activities/list'] || [])
        .filter(p => {
          if (p.type !== ActivityType.Presentation)
            return false
          //if (p.content.locale !== Locale.en || p.content.locale !== this.$store.state.ui.locale)
            //return false
          //const d = dayjs.unix(p.content.start.seconds)
          const startPoint = this.jsday.unix()
          const ppoint = p.start.seconds

          return ppoint > startPoint && ppoint - startPoint <= 60*60*24
          //return this.day.isSame(d, 'day')
          //const end = dayjs.unix(p.content.end.seconds)
          //return now.isBefore(end)
        })
        .sort((a,b) => a.start.seconds === b.start.seconds ? 0 : a.start.seconds > b.start.seconds ? 1 : -1)
    },
    scenesList() {
      return this.presentations.map(p => p.content.scene).filter((p, i, s) => s.indexOf(p) === i)
    },
    dayPx: () => 50,
    icsdayhref() {
      if (!this.ics) return ''
      const base = btoa(this.ics)
      return `data:text/calendar;base64,${base}`
    }
  }

}
</script>

<template>
  <OverlayPage @close="$router.push({name:'3DPage'})">
    <div class="threed-schedule-page h-full d-flex flex-column overflow-hidden">

    <div class="threed-schedule-header-row flex-shrink-0 mb-6">
      <div class="calendar-sidebar-width flex-shrink-0">
          <div class="d-flex align-items-center">
            <!-- date controls -->
            <div class="header-date">
              <div class="d-flex">
                <v-btn
                  size="x-small"
                  flat
                  color="black"
                  elevation="0"
                  icon="mdi-arrow-left"
                  @click="goMinusOne()"
                />
                <div class="align-self-center mx-2">{{day.format('MM/DD')}}</div>
                <v-btn
                  size="x-small"
                  flat
                  elevation="0"
                  color="black"
                  icon="mdi-arrow-right"
                  @click="goPlusOne()"
                />
              </div>
              <v-btn
                color="black"
                size="x-small"
                flat
                elevation="0"
                @click="cycleTimeZone()"
              >
                {{$t(`calendar.timezones.${this.timezone}`)}} 
                {{$t('calendar.timezone')}} 
              </v-btn>
            </div>
            <!-- ics button -->
            <div>
              <a
                :disabled="!ics"
                class="download-a add-to-calendar-button mr-5"
                :href="icsdayhref"
                target="_blank"
                :download="`facebook-hackers-academy-${day.format('MM-DD')}.ics`"
                :title="$t('hoverLabels.addTocalendar')"
              >
                <svg width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <rect x="4" y="5" width="16" height="16" rx="2" />
                  <line x1="16" y1="3" x2="16" y2="7" />
                  <line x1="8" y1="3" x2="8" y2="7" />
                  <line x1="4" y1="11" x2="20" y2="11" />
                  <line x1="10" y1="16" x2="14" y2="16" />
                  <line x1="12" y1="14" x2="12" y2="18" />
                </svg>
                <span>{{ $t('hoverLabels.addTocalendar') }}</span>
              </a>
            </div>
            <!--/ ics button -->
          </div>
        </div>
    </div>
    <div class="overflow-y-auto custom-scrollbar flex-grow-1 pt-2 px-5">
      <div 
        class="pa-1 w-100 threed-schedule-event mb-2" 
        v-for="event, index in presentations" 
        :key="index"
        @click="openPreview(event)"
      >
        <div class="tray-item d-flex h-full py-6 px-8" :class="event.content.break ? 'bg-white' : 'bg-fb-navy'">
          <div class="tray-item-meta">
            <b>{{ $l(event.content.name) }}</b>
            <table>
              <tr>
                <td>{{$t('calendar.from')}}</td>
                <td class="pl-2">: {{ timeStampToTime(event.start.seconds) }}</td>
              </tr>
              <tr>
                <td>{{$t('calendar.to')}}</td>
                <td class="pl-2">: {{ timeStampToTime(event.end.seconds) }}</td>
              </tr>
            </table>
          </div>
          <div class="tray-item-speakers-meta ml-6 pl-6 d-flex align-items-center" v-if="!event.content.break">
            <table>
              <tr>
                <td>{{$t('calendar.speakers')}}</td>
                <td class="pl-2">: {{ event.content.speakers }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div v-if="!presentations.length">
        {{$t('calendar.noEvents')}} {{day.format('MM/DD')}}
      </div>
    </div>

      
    </div>
    <CalenderEventPreview
     :modelValue="eventValues" 
     v-if="eventPreviewVisible" 
     :isVisible="eventPreviewVisible" 
     :tz="timeZones[this.timezone]"
     @close="closePreview"
     @joinTeams="joinTeams" />

     <!-- send to teams popup -->
     <SendToTeamsPopup
     :link="teamsLink" 
     v-if="teamsLinkPopup" 
     :isVisible="teamsLinkPopup" 
     @close="closeSendToTeamsPopup" />
  </OverlayPage>
</template>

<style lang="scss">
.threed-schedule-time-sidebar{
  width:100px;
}
.calendar-sidebar-time-label{
  transform: translateY(-60%);
  display: inline-block;
}
.threed-schedule-time-row{
  border-top: 1px solid #222;
}
.threed-schedule-time-sidebar-row, .threed-schedule-time-row{
  height: 50px;
}
.threed-schedule-event{
  left:0;
}
.tray-item-meta{
  min-width: 200px;
}
.tray-item-speakers-meta{
  border-left: 1px solid #999;
}
.threed-schedule-event > div{
  padding: 3px 5px 3px 6px;
  font-size: 14px;
  cursor: pointer;
  top: 0;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
  border-radius: 4px;
}
.add-to-calendar-button{
  position: relative;
  svg{
    margin-bottom: -8px;
    margin-left: 5px;
  }
  span{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 20px);
    background-color: rgb(var(--v-theme-white));
    color: rgb(var(--v-theme-black));
    padding: 4px 10px;
    font-size: 14px;
    border-radius: 6px;
    white-space: nowrap;
    opacity: 0;
    transition: all .5s;
    pointer-events: none;
    box-shadow: 0 0 4px 0 rgba(var(--v-theme-white),.5);
  }
  &:hover{
    span{
      opacity: 1;
      top: calc(100% + 10px);
    }
  }
}

</style>
