<script>
import FilesList from '@/components/FilesList'
import ScoresView from '@/components/UserScoreTable'

import { MIME } from '@/lib/enums'

const filesTypesMap = {
  images: [MIME['image/webp']],
  documents: [MIME['text/plain'], MIME['application/pdf'], MIME['text/csv']],
  audio: [MIME['audio/webm']],
  video: [MIME['video/webm']]
}

export default {
  name: 'Files',
  data: () => ({
    media: []
  }),
  components: {
    FilesList,
    ScoresView
  },
  computed: {
    mode() {
      return this.$route.params.type === 'score' ? 'score' : 'files'
    },
    filesFilter() {
      return !this.$route.params.type ||
        this.$route.params.type === 'all' ? 'all' : this.$route.params.type
    },
    files() {
      const docs = this.$store.getters['user/media/list'] || []
      const filters = filesTypesMap[this.filesFilter]
      return this.filesFilter === 'all'
        ? docs
        : docs.filter(file => filters && filters.includes(file.MIME))
    }
  }
}
</script>

<template>
  <div class="h-full d-flex flex-column">
    <h2>{{$t('userPage.files.title')}}</h2>
    <hr class="opacity mb-4" />
    <div class="w-full flex-grow d-flex flex-column overflow-hidden">
      <div class="file-types-wrappper mb-6">
        <router-link :to="{ name: 'Files' }">{{ $t('userPage.files.types.all') }}</router-link>
        <router-link :to="{ name: 'Files', params: { type: 'documents'} }">{{ $t('userPage.files.types.documents') }}</router-link>
        <!-- <router-link :to="{ name: 'Files', params: { type: 'audio'} }">{{ $t('userPage.files.types.audio') }}</router-link> -->
        <router-link :to="{ name: 'Files', params: { type: 'video'} }">{{ $t('userPage.files.types.video') }}</router-link>
        <!-- <router-link :to="{ name: 'Files', params: { type: 'images'} }">{{ $t('userPage.files.types.image') }}</router-link> -->
        <router-link :to="{ name: 'Files', params: { type: 'score'} }">{{ $t('userPage.files.types.score') }}</router-link>
      </div>
      <FilesList v-if="mode === 'files'" :files="files"/>
      <ScoresView v-else />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#upload-file-btn {
  cursor: pointer;
}
#upload-file {
  display: none;
}
.file-types-wrappper{
  a{
    color: #fff;
    display: inline-block;
    text-decoration:none;
    margin-right:10px;
    font-weight: 500;
    border-bottom:2px solid transparent;
    &[aria-current="page"]{
      border-color:rgb(var(--v-theme-primary));
    }
    &:not([aria-current="page"]):hover{
      border-color:rgb(var(--v-theme-grey-200));
    }
  }
}
</style>
