
const loadImage = url => new Promise(resolve => {
  const request = new XMLHttpRequest();
  request.onreadystatechange = () => {
    if (request.readyState === 4) {
      if (request.status === 200) {
        resolve(Buffer.from(request.response));
      } else
        resolve(null)
    }
  }
  request.open('GET', url, true);

  //https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest#handling_binary_data
  request.responseType = "arraybuffer";

  request.send();
})

export default loadImage
