<script>
import OverlayPage from '@/components/OverlayPage.vue'
import TeamsImage from "@/assets/imgs/teams.png"

export default {
  name: 'Teams',
  components: {
    OverlayPage,
  },
  data(){
    return{
      iframe: "https://teams.microsoft.com/_#/l/meetup-join/19:meeting_MjQ1MzJlYzEtOThlMy00YjY5LTk3MmQtYjgyM2U4YTNlZjRh@thread.v2/0?context=%7B%22Tid%22:%22a45419ba-f372-4bbd-8394-855b6d6074d8%22,%22Oid%22:%227d5034e0-24aa-4e20-aa13-0987dffa41f6%22,%22IsBroadcastMeeting%22:true%7D&btype=a&role=a&anon=true&deeplinkId=6279f8a1-0809-4f7e-bb87-bb4553cbd007"
    }
  },
  setup: () => ({
    TeamsImage
  })
}
</script>

<template>
<OverlayPage @close="$router.push({name:'3DPage'})">
  <div class="position-relative h-full overflow-y-auto custom-scollbar">
    <div class="no-meeting-wrapper">
      <img class="no-team-meeting" :src="TeamsImage" alt="no meeting started" />
    </div>
  </div>
</OverlayPage>
</template>

<style lang="scss" scoped>
  .no-team-meeting{
    width:100%;
    height: auto;
  }
  .no-meeting-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }
</style>
