<script>
//import { MIME } from '@/lib/enums'

export default {
  name: 'Gallery',
  // data: () => ({
  //   rows: []
  // }),
  // created() {
  //   let row = 7
  //   let lastRow = 0
  //   this.files.forEach(img => {
  //     if (!this.rows[lastRow])
  //       this.rows[lastRow] = []
  //     this.rows[lastRow].push(img)
  //     row --
  //     if (row === 0) {
  //       lastRow++
  //       row = 7
  //     }
  //   })
  // },
  // computed:{
  //   files() {
  //     const docs = this.$store.getters['user/media/list'] || []
  //     const filters = [MIME['image/webp']];
  //     return docs.filter(file => filters && filters.includes(file.MIME))
  //   }
  // }
}
</script>

<template>
  <div class="h-full d-flex flex-column">
    <h2>{{$t('userPage.gallery.title')}}</h2>
    <hr class="opacity mb-4" />
    <div class="flex-grow overflow-y-auto custom-scrollbar">
      <!-- TODO : Refactor gallery -->
      <!-- <div
        v-for="row, ri of rows"
        :key="ri"
        class="gellary-grid"
      >
        <div v-for="img, imgi of row" :key="imgi">
          <img :src="img.details.url" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.gellary-grid{
  display: grid;
  grid-template-rows:repeat(4, 100px);
  grid-template-columns:repeat(5, 1fr);
  grid-gap: 10px;
  &+.gellary-grid{
    margin-top:10px;
  }
  &>div{
    img{
      width:100%;
      height:100%;
      object-fit:cover;
    }
  }
  &>div:first-child{
    grid-row: 1/4;
    grid-column: 1/3;
  }
  &>div:nth-child(02){
    grid-row: 1/2;
    grid-column: 3/4;
  }
  &>div:nth-child(03){
    grid-row: 1/3;
    grid-column: 4/6;
  }
  &>div:nth-child(04){
    grid-row: 2/3;
    grid-column: 3/4;
  }
  &>div:nth-child(05){
    grid-row: 3/5;
    grid-column: 3/6;
  }
  &>div:nth-child(06){
    grid-row: 4/5;
    grid-column: 1/2;
  }
  &>div:nth-child(07){
    grid-row: 4/5;
    grid-column: 2/3;
  }
}
</style>
