/** Mapping of scenes names in vue to names in playcanvas */

const pcScene = (scene, notNull = true) => {
  const scenes = {
    campus: 'FBReality_Campus',
    pool: 'FBReality_Rooftop',
    apartment: 'FBReality_Apartment',
    loft: 'FBReality_Loft',
    store: 'FBReality_Store',
    office: 'FBReality_Office',
    warehouse: 'FBReality_Warehouse',
  }
  return scenes[scene]
    ? scenes[scene]
    : notNull
      ? scenes.campus
      : undefined
}

export default pcScene
