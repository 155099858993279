<script>
import waitFor from '@/utils/waitFor'

export default {
  name: 'UiMusicPlayer',
  props: {
    sceneName: {
      type: String,
      default: ''
    }
  },
  async created() {
    await waitFor(0)
    this.updateVolume()
  },
  methods: {
    updateVolume() {
      const p = this.$refs.player
      if (!p) return
      const next = this.$store.state.ui.volume.music
      const vol = next/100
      // console.log('next music vol', vol)
      if (vol < 0)
        return
      p.volume = vol
      if (vol < 0.08) {
        p.volume = 0
        p.pause()
      } else {
        if (p.paused) {
          p.load()
          p.play()
        }
      }
 
    }
  },
  watch: {
    async sceneName() {
      // console.log('next audio', next)
      await waitFor(0)
      if (this.$refs.player.volume) {
        this.$refs.player.load()
        this.$refs.player.play()
      }
    },
    '$store.state.ui.volume.music'() {
      this.updateVolume()
    }
  }
}
</script>

<template>
<audio loop ref="player">
  <source :src="`/music/${sceneName}.mp3`" type="audio/mpeg"/>
</audio>
</template>
