<script>
import waitFor from '@/utils/waitFor'
// for Clay tests
import loadImage from '@/utils/loadImage'
import pcScene from '@/utils/pcScene'
import loadModulesMixin from '@/mixins/loadModulesMixin.js'

import * as Sentry from "@sentry/vue";

import BgImg from '@/assets/imgs/background2d.png'

export default {
  name: 'PlayCanvasStage',
  inject: ['pc'],
  mixins: [loadModulesMixin],
  props: {
    sceneName: {
      type: String,
      required: true
    },
    settings: {
      type: Object,
      default: () => (null)
    }
  },
  emits: [
    'touchpoint',
    'easteregg',
    'sceneEnter',
    'sceneLeave'
  ],
  data() {
    return {
      appSettings: this.settings,
      app: null,
      canvas: null,
      errorMessage: null,
      scene: null,
      appWidth: 0,
      appHeight: 0
    }
  },
  async created() {
    await this.createApp()
  },
  onBeforeUnmount() {
    this.destroyApp()
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('orientationchange', this.resize)
  },
  computed: {
    canvasClass() {
      let cls = this.app ? `fill-mode-${this.app._fillMode}` : ''
      if (!this.$store.state.pc.splash)
        cls += ' show'
      return cls
    },
    pcSceneName() {
      return pcScene(this.sceneName)
    }
  },
  watch: {
    async sceneName(next, prev) {
      //console.log('next sceneName', next, prev)
      this.$store.commit('pc/setSplash', {
        loader: false,
        video: this.sceneName,
        skip: false
      })
      this.$store.dispatch('scene/leave', prev)
      this.app.fire('changeScenesExternal', this.pcSceneName)
      // avarage loader video as 12sec
      this.$store.dispatch('scene/enter', this.sceneName)
      await waitFor(12000)
      this.$store.commit('pc/setSplash', null)
    },
    settings: {
      deep: true,
      async handler(next) {
        console.log('new playcanvas settings?', next)
      }
    },
    '$store.state.ui.open'(next) {
      // console.log('ui:open changed', next)
      if (this.app)
        this.app.fire('ui:open', next)
    }
  },
  methods: {
    async createApp() {
      await waitFor(0)
      this.canvas = this.$refs.mainStage
      const { app, error, errorLink } = this.initialize()
      if (error) {
        this.$store.commit('ui/setError', { error, errorLink })
        this.$router.push({ name: 'ErrorPage' })
        return
      }
      this.app = app
      this.app.vueRebind = () => {
        this.bindApp()
      }
      this.$store.commit('pc/setApp', app)
      this.$store.commit('pc/setSplash', {
        loader: true,
        video: this.sceneName,
        skip: false
      })
      this.bindApp()
      if (this.appSettings.PRELOAD_MODULES.length > 0) {
        await this.processModules()
      }
      const ok = await this.configure()
      if (!ok) {
        this.$store.commit('ui/setError', { error: 'error.playcanvas.initialization' })
        this.$router.push({ name: 'ErrorPage' })
        return
      }
      const loadError = await this.doPreloadAssets()
      if (loadError) {
        this.$store.commit('ui/setError', { error: 'error.playcanvas.preloadAssets' })
        this.$router.push({ name: 'ErrorPage' })
        return
      }
      const scene = await this.loadScene()
      if (!scene) {
        this.$store.commit('ui/setError', { error: 'error.playcanvas.loadScene' })
        this.$router.push({ name: 'ErrorPage' })
        return
      }
      this.$store.commit('pc/setLoad', 100)
      this.scene = scene
      this.$store.commit('pc/setScene', scene)
      this.app.start()
    },
    bindApp() {
      //console.log('bind app')
      this.app.on('preload:progress', p => {
        const load = Math.ceil(p*100)
        this.$store.commit('pc/setLoad', load)
      })
      this.app.on('preload:end', () => {
        this.app.off('preload:progress')
      })
      /*
      loadImage(BgImg)
        .then(texture => {
          console.log('bgimg', BgImg, texture)
        })
      */
      this.app.on('3d:sendTexture', async (scene, id) => {
        const texture = await loadImage(BgImg)
        console.log('** VUE 3d:sendTexture', scene, id, texture)
        if (texture)
          this.app.fire('3d:setTexture', scene, id, texture)
          console.log('3d:setTexture fired')
      })
      // this.app.on('3d:tp', (scene, id) => {
        // console.log('3d:tp', scene, id)
        // this.$emit('touchpoint', {scene, id})
      // })
      this.app.on('3d:easteregg', (scene, id) => {
        // console.log('3d:easteregg', scene, id)
        this.$emit('easteregg', {scene, id})
      })
      this.app.on('3d:touchpoint', (scene, id) => {
        //console.log('3d:touchpoint', scene, id)
        this.$emit('touchpoint', {scene, id})
      })
      this.app.on('sceneloader:loading', () => {
        this.$store.commit('pc/setSkip', false)
        //waitFor(0).then(() => {
          //this.app.once('sceneloader:load', () => {
            //this.bindApp()
          //})
        //}) 
      })
      this.app.on('sceneloader:load', () => {
        this.$store.commit('pc/setSkip', true)
        this.$store.dispatch('scene/enter', this.sceneName)
        //this.bindApp()
      })
      this.app.once('sceneloader:ready', () => {
        // console.log('sceneloader:ready')
        if (this.sceneName !== 'campus')
          this.app.fire('changeScenesExternal', this.pcSceneName)
        else
          this.$store.dispatch('scene/enter', this.sceneName)
      })
      this.app.on('start', async () => {
        this.app.fire('changeScenesExternal', this.pcSceneName)
        await waitFor(1000)
        this.$store.commit('pc/setSplash', null)
      })
    },
    /**
     * Destroys PlayCanvas application
     */
    async destroyApp() {
      this.$store.commit('pc/setSplash', {
        loader: false,
        video: ''
      })
      if (!this.app) return
      console.log('destroy app')
      await this.app.destroy()
      console.log('destroy app done')
      this.app = null
    },
    /**
     * Initialize PlayCanvas application
     */
    initialize() {
      const result = {
        app: null,
        error: null,
        errorLink: null
      }
      try {
        const devices = this.createInputDevices()
        result.app = new this.pc.Application(this.canvas, {
          elementInput: devices.elementInput,
          keyboard: devices.keyboard,
          mouse: devices.mouse,
          gamepads: devices.gamepads,
          touch: devices.touch,
          graphicsDeviceOptions: this.appSettings.CONTEXT_OPTIONS,
          assetPrefix: this.appSettings.ASSET_PREFIX || '',
          scriptPrefix: this.appSettings.SCRIPT_PREFIX || '',
          scriptsOrder: this.appSettings.SCRIPTS || [],
        })
      } catch (e) {
        Sentry.captureException(e)
        if (result.app) {
          result.app.destroy()
          result.app = null
        }
        if (e instanceof this.pc.UnsupportedBrowserError) {
          result.error = 'error.webGL.unsupportedBrowser'
          result.errorLink = 'http://get.webgl.org'
        } else if (e instanceof this.pc.ContextCreationError) {
          result.error = 'error.webGL.unsupportedDevice'
          result.errorLink = 'http://get.webgl.org/troubleshooting/'
        } else {
          console.log('Could not initialize application. Error: ' + e.toString())
          result.error = 'error.webGL.unsupportedDevice'
          result.errorLink = 'http://get.webgl.org/troubleshooting/'
        }
      }
      return result
    },
    /**
     * Configure and start PlayCanvas application, use config.json
     */
    configure() {
      return new Promise(accept => {
        this.app.configure(this.appSettings.CONFIG_FILENAME, async err => {
          if (err) {
            console.error('configure error', err)
            accept(false)
            return
          }
          window.addEventListener('resize', this.resize, false)
          window.addEventListener('orientationchange', this.resize, false)
          // Add dynamic CSS
          // Do the first resize after a timeout because of
          // iOS showing a squished iframe sometimes
          await waitFor(250)
          this.resize()
          this.configureCss(this.app._fillMode, this.app._width, this.app._height)
          accept(true)
        })
      })
    },
    /**
     * 	Load all assets in the asset registry that are marked as 'preload'
     */
    doPreloadAssets() {
      return new Promise(accept => {
        this.app.preload(err => {
          if (err) {
            console.error('Preload Error: ', err)
            accept(err)
            return
          }
          // Trigger event when the app is configured and assets are preloaded
          console.log('Assets preloaded.')
          this.app.fire('app:configured')
          accept(null)
        })
      })
    },
    /**
     * Initialize the WebAudio Context (not used by now)
     */
    /*
    initAudioContext() {
      // const app = this.$pc.Application.getApplication();
      const resumeContext = function() {
        this.app.systems.sound.manager.context.resume();
        window.removeEventListener('mousedown', resumeContext);
        window.removeEventListener('touchend', resumeContext);
      }
      window.addEventListener('mousedown', resumeContext);
      window.addEventListener('touchend', resumeContext);
    },
    /**
     * Load the current given scene
     */
    loadScene() {
      return new Promise(accept => {
        // console.log('Loading Scene: ', this.appSettings.SCENE_PATH);
        this.app.loadScene(this.appSettings.SCENE_PATH, (err, scene) => {
          if (!err) {
            // this.app.fire('preload:end')
            accept(scene)
          } else {
            console.log('Loading Scene Error: ', err)
            accept(null)
          }
        })
      })
    },
    /**
     * Process module loading and initialize Basis compression
     */
    async processModules() {
      // console.log('required processModules')
      await this.loadModules(this.appSettings.PRELOAD_MODULES,this.appSettings.ASSET_PREFIX)

      // Check for BASIS texture compression
      const moduleBasis = this.appSettings.PRELOAD_MODULES.find(module => module.moduleName === 'BASIS')
      if (!!moduleBasis && !this.$store.state.basisModuleLoaded)
        await new Promise(accept => {
          this.pc.basisDownload(
            moduleBasis.glueUrl,
            moduleBasis.wasmUrl,
            moduleBasis.fallbackUrl,
            () => { accept() }
          )
        })
    },
    /**
     * Handle resize canvas
     */
    resize() {
      this.app.resizeCanvas(this.canvas.width, this.canvas.height)
      this.canvas.style.width = ''
      this.canvas.style.height = ''
      const fillMode = this.app._fillMode
      if (fillMode === this.pc.FILLMODE_NONE || fillMode === this.pc.FILLMODE_KEEP_ASPECT) {
        if (
          (fillMode == this.pc.FILLMODE_NONE &&
            this.canvas.clientHeight < window.innerHeight) ||
          this.canvas.clientWidth / this.canvas.clientHeight >=
            window.innerWidth / window.innerHeight
        ) {
          this.canvas.style.marginTop =
            Math.floor((window.innerHeight - this.canvas.clientHeight) / 2) + 'px'
        } else {
          this.canvas.style.marginTop = '';
        }
      }
    },
    /**
     * Create PlayCanvas input devices from stettings
     */
    createInputDevices() {
      const devices = {
        elementInput: new this.pc.ElementInput(this.canvas, {
          useMouse: this.appSettings.INPUT_SETTINGS.useMouse,
          useTouch: this.appSettings.INPUT_SETTINGS.useTouch,
        }),
        keyboard: this.appSettings.INPUT_SETTINGS.useKeyboard
          ? new this.pc.Keyboard(window)
          : null,
        mouse: this.appSettings.INPUT_SETTINGS.useMouse ? new this.pc.Mouse(this.canvas) : null,
        gamepads: this.appSettings.INPUT_SETTINGS.useGamepads
          ? new this.pc.GamePads()
          : null,
        touch:
          this.appSettings.INPUT_SETTINGS.useTouch && this.pc.platform.touch
            ? new this.pc.TouchDevice(this.canvas)
            : null,
      }
      return devices
    },
    /**
     * Add dynamic CSS
     */
    configureCss(fillMode, width, height) {
      if (this.canvas.classList)
        this.canvas.classList.add('fill-mode-' + fillMode)
      this.appWidth = width;
      this.appHeight = height;
    }
  }
}
</script>

<template>
  <canvas
    ref="mainStage"
    :class="canvasClass"
  />
</template>

<style lang="scss" scoped>
canvas {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms;
  &.show {
    opacity: 1;
    visibility: visible;
  }
  &.fill-mode-NONE {
    margin: auto;
  }
  &.fill-mode-KEEP_ASPECT {
    width: 100%;
    height: auto;
    margin: 0;
  }
  &.fill-mode-FILL_WINDOW {
    width: 100%;
    height: 100vh;
    margin: 0;
  }
  &:focus {
    outline: none;
  }
}
@media screen and (min-aspect-ratio: var(--app-width) / var(--app-height)) {
  canvas.fill-mode-KEEP_ASPECT {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}
</style>
