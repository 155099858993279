const ASSET_PREFIX = "/playcanvas/"
const SCRIPT_PREFIX = "/playcanvas/"
const SCENE_PATH = "1198365.json"
const CONTEXT_OPTIONS = {
  'antialias': true,
  'alpha': false,
  'preserveDrawingBuffer': false,
  'preferWebGl2': true,
  'powerPreference': "default"
}
const SCRIPTS = [ 52317228 ]
const CONFIG_FILENAME = "/playcanvas/config.json"
const INPUT_SETTINGS = {
  useKeyboard: true,
  useMouse: true,
  useGamepads: false,
  useTouch: true
}
const PRELOAD_MODULES = [
  {'moduleName' : 'Ammo', 'glueUrl' : 'files/assets/52317212/1/ammo.wasm.js', 'wasmUrl' : 'files/assets/52317213/1/ammo.wasm.wasm', 'fallbackUrl' : 'files/assets/52317211/1/ammo.js', 'preload' : true},
]

export default { ASSET_PREFIX, SCRIPT_PREFIX, SCENE_PATH, CONTEXT_OPTIONS, SCRIPTS, CONFIG_FILENAME, INPUT_SETTINGS, PRELOAD_MODULES }